import moment from "moment";
import { TypedDocument } from "shared/models-web";

export function createTypedDocument(type: string, settings: any): TypedDocument {
    return {
        paymentStatus: {
            prevStatuses: [],
            status: settings.payments.defaultStatus
        },
        idents: [],
        year: Number.parseInt(moment().format("YYYY")),
        month: moment().format("MMMM"),
        status: settings.defaultStatus,
        type,
        sent: []
    };
}