import Auth from "../utils/auth";

export interface AuthInfo {
    username: string,
    password: string;
}

export default class PasswordAuth extends Auth<AuthInfo> {

    login = async (): Promise<(info: AuthInfo) => Promise<boolean>> => {


        return async (info: AuthInfo) => {
            const result = await this.store.authApi.login(info.username, info.password);

            await this.store.get_sessions().update_session(result.token);

            return true;
        };
    }

}