import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Alert, Form } from "react-bootstrap";
import { Units } from "shared/models-web";
import Table from "../../components/ui/Table";
import Store from "../../libs/store";
import { timeout } from "../../libs/utils/utils";
import Popup from "../../components/ui/Popup";
import { IdentResolverReference } from "shared/system";
import _ from "lodash";

@observer
export default class UnitsList extends React.Component<{ store: Store }> {
    @observable
    private data: Units[] = [];
    @observable
    hasError = false;
    @observable
    errorMessage = "";

    @observable
    showAddUpdateForm = false;
    popupHasError = false;
    @observable
    popupErrorMessage = "";

    @observable
    unit: Units = {
        name: "",
        symbol: "",
    };
    @observable
    unitId?: string;

    constructor(props: any) {
        super(props);

        this.init();
        makeObservable(this);
    }

    @action
    init = async () => {
        try {
            const data = await this.props.store.codetablesApi.list_unit();

            this.data = data.data.units;
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    @action
    load = async (unitId: string) => {
        try {
            const data = await this.props.store.codetablesApi.load_unit(unitId);

            this.unit = data.data.unit;
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.popupErrorMessage = e.response.data.errMsg;
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        }
    };

    @action
    onOpenAddEditUnit = async (unitId?: string) => {
        this.unitId = unitId;

        if (unitId) {
            await this.load(unitId);
        } else {
            this.unit = {
                name: "",
                symbol: "",
            };
        }

        this.showAddUpdateForm = true;
    };

    @action
    onCloseAddEditUnit = () => {
        this.showAddUpdateForm = false;
    };

    @action
    onSaveAddEditUnit = async () => {
        try {
            if (this.unitId) {
                await this.props.store.codetablesApi.update_unit(this.unitId, this.unit);
            } else {
                await this.props.store.codetablesApi.add_unit(this.unit);
            }

            this.showAddUpdateForm = false;
            await this.init();
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.popupErrorMessage = e.response.data.errMsg;
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        }
    };

    render(): React.ReactNode {
        return (
            <>
                <ul className="breadcrumb">
                    <li className="breadcrumb-item">HOME</li>
                    <li className="breadcrumb-item">CODETABLES</li>
                    <li className="breadcrumb-item active">UNITS</li>
                </ul>

                <h1 className="page-header">
                    Units <small>View the list and more</small>
                </h1>

                {this.hasError && <Alert variant="danger">{this.errorMessage}</Alert>}

                <div className="d-flex align-items-center mb-3">
                    <div className="btn-group">
                        <button type="button" className="btn btn-sm btn-outline-theme rounded-0" onClick={() => this.onOpenAddEditUnit()}>
                            <i className="fa fa-fw fa-plus" />
                            &nbsp;
                            <span>Add</span>
                        </button>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <Table
                            columns={[
                                {
                                    Header: "Name",
                                    accessor: "name",
                                    disableSortBy: false,
                                },
                                {
                                    Header: "Symbol",
                                    accessor: "symbol",
                                    disableSortBy: false,
                                },
                                {
                                    Header: "Ref",
                                    accessor: "calculationRef",
                                    disableSortBy: false,
                                },
                                {
                                    Header: "Actions",
                                    accessor: "action",
                                    disableSortBy: true,
                                    actions: (row: any) => {
                                        return (
                                            <div className="btn-group btn-group-sm">
                                                <button type="button" className="btn btn-outline-theme btn-sm" onClick={() => this.onOpenAddEditUnit(row.original._id)}>
                                                    Edit
                                                </button>
                                                <button type="button" className="btn btn-outline-theme btn-sm">
                                                    Delete
                                                </button>
                                            </div>
                                        );
                                    },
                                },
                            ]}
                            data={this.data}
                        />
                    </div>
                    <div className="card-arrow">
                        <div className="card-arrow-top-left"></div>
                        <div className="card-arrow-top-right"></div>
                        <div className="card-arrow-bottom-left"></div>
                        <div className="card-arrow-bottom-right"></div>
                    </div>
                </div>

                <Popup title="Enter details " show={this.showAddUpdateForm} onClose={this.onCloseAddEditUnit} onSave={this.onSaveAddEditUnit}>
                    {this.popupHasError && <Alert variant="danger">{this.popupErrorMessage}</Alert>}

                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Name" value={this.unit && this.unit.name} onChange={action((e) => this.unit && (this.unit.name = e.target.value))} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="symbol">
                        <Form.Label>Symbol</Form.Label>
                        <Form.Control type="text" placeholder="Symbol" value={this.unit && this.unit.symbol} onChange={action((e) => this.unit && (this.unit.symbol = e.target.value))} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="calculationRef">
                        <Form.Label>Calculation reference</Form.Label>
                        <Form.Select placeholder="Unit calculation reference" value={this.unit && this.unit.calculationRef} onChange={action((e) => this.unit && (this.unit.calculationRef = e.target.value))}>
                            <option>Select ...</option>

                            {_.map(IdentResolverReference, (entry, index) => {
                                return <option key={index} value={index}>{entry}</option>;
                            })}
                        </Form.Select>
                    </Form.Group>
                </Popup>
            </>
        );
    }
}
