import { NotificationListRequest } from "shared/models-web";
import Api from "../utils/api";

export default class NotificationApi extends Api {
    private static PREFIX = "/api/v1/notifications";


    get = async (params: NotificationListRequest) => {
        return (await this.axios.post(`${NotificationApi.PREFIX}/`, params)).data;
    }

    mark_read = async (id: string) => {
        return (await this.axios.post(`${NotificationApi.PREFIX}/mark/${id}`)).data;
    }

    dismiss = async (id: string) => {
        return (await this.axios.post(`${NotificationApi.PREFIX}/dismiss/${id}`)).data;
    }

    remove = async (id: string) => {
        return (await this.axios.post(`${NotificationApi.PREFIX}/remove/${id}`)).data;
    }

    dismiss_all = async () => {
        return (await this.axios.post(`${NotificationApi.PREFIX}/dismissall`)).data;
    }
}