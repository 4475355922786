import Store from "../store";


export default abstract class Auth<T> {
    protected store: Store;

    constructor(store: Store) {
        this.store = store;    
    }

    abstract login(): Promise<(info: T) => Promise<boolean>>;
}