import _ from "lodash";
import { action, makeObservable, observable, toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Alert, ButtonGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DocumentTypeConfiguration, FileContext, FileEntry } from "shared/models-web";
import Store from "../../libs/store";
import { timeout, toBase64url } from "../../libs/utils/utils";

@observer
export default class Templates extends React.Component<{ store: Store }> {
    @observable
    hasError = false;
    @observable
    errorMessage = "";

    @observable
    files: FileEntry[] = [];
    @observable
    documentTypes: DocumentTypeConfiguration[] = [];
    @observable
    typedFiles: { [id: string]: FileContext } = {};

    constructor(props: any) {
        super(props);

        makeObservable(this);

        this.load();
    }

    @action
    load = async () => {
        try {
            this.files = (await this.props.store.editorApi.list_files()).data.files;
            const data = (await this.props.store.editorApi.context()).data;

            this.documentTypes = data.types;
            this.typedFiles = data.typedFiles;

            console.log(toJS(this.typedFiles));
            console.log(toJS(this.documentTypes));
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    render(): React.ReactNode {
        return (
            <>
                <ul className="breadcrumb">
                    <li className="breadcrumb-item">HOME</li>
                    <li className="breadcrumb-item">SETTINGS</li>
                    <li className="breadcrumb-item active">TEMPLATES</li>
                </ul>

                <h1 className="page-header">
                    Templates <small>edit pdfs and emails</small>
                </h1>

                {this.hasError && <Alert variant="danger">{this.errorMessage}</Alert>}

                <div className="mb-5">
                    <h4>
                        <i className={"fa-fw text-theme far fa-copy"}></i> Document types
                    </h4>
                    <p>Edit individual templates related to given document types</p>

                    <div className="card">
                        <div className="list-group list-group-flush">
                            {_.map(this.documentTypes, (type) => {
                                const fileContext = this.typedFiles[type.id];

                                if(!fileContext) {
                                    return <></>;
                                }

                                return (
                                    <div className="list-group-item d-flex align-items-center">
                                        <div className="flex-1 text-break">
                                            <div>{type.name}</div>
                                            <div className="text-white text-opacity-50 d-flex align-items-center">
                                                <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> Templates: pdf, email
                                            </div>
                                        </div>
                                        <div>
                                            <ButtonGroup>
                                                <Link to={"/editor/files/" + toBase64url(fileContext.pdf)} className="btn btn-outline-default w-100px">
                                                    Edit PDF
                                                </Link>
                                                <Link to={"/editor/files/" + toBase64url(fileContext.email)} className="btn btn-outline-default w-100px">
                                                    Edit Email
                                                </Link>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        <div className="card-arrow">
                            <div className="card-arrow-top-left"></div>
                            <div className="card-arrow-top-right"></div>
                            <div className="card-arrow-bottom-left"></div>
                            <div className="card-arrow-bottom-right"></div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
