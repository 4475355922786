import React from "react";
import Header from "./components/header";
import Menu from "./components/menu";
import Store from "./libs/store";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Dashboard from "./modules/dashboard";
import Companies from "./modules/codetables/companies";
import Materials from "./modules/codetables/materials";
import UnitsList from "./modules/codetables/units";
import TaxRates from "./modules/codetables/taxrates";
import Users from "./modules/users";
import FolderErrors from "./modules/folders/errors";
import Login from "./pages/login";
import { observer } from "mobx-react";
import Documents from "./modules/documents";
import Configuration from "./modules/editor/confguration";
import Templates from "./modules/editor/templates";
import EditFile from "./modules/editor/edit_file";
import Notifications from "./modules/notifications";
import NotificationToast from "./components/ui/NotificationToast";

@observer
export default class App extends React.Component<{ store: Store }> {
    render(): React.ReactNode {
        if (!this.props.store.loginState.is_loggined) {
            return <Login store={this.props.store} />;
        }

        return (
            <>
                <BrowserRouter>
                    <Header store={this.props.store}></Header>
                    <Menu store={this.props.store} />

                    <div id="content" className="app-content">
                        <Routes>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/ct/companies" element={<Companies store={this.props.store} />} />
                            <Route path="/ct/materials" element={<Materials store={this.props.store} />} />
                            <Route path="/ct/units" element={<UnitsList store={this.props.store} />} />
                            <Route path="/ct/taxrates" element={<TaxRates store={this.props.store} />} />
                            <Route path="/folders/errors" element={<FolderErrors store={this.props.store} />} />
                            <Route path="/users" element={<Users store={this.props.store} />} />
                            <Route path="/documents/:type" element={<Documents store={this.props.store} />} />
                            <Route path="/editor/config" element={<Configuration store={this.props.store} />} />
                            <Route path="/editor/files" element={<Templates store={this.props.store} />} />
                            <Route path="/editor/files/:file" element={<EditFile store={this.props.store} />} />
                            <Route path="/notifications" element={<Notifications store={this.props.store} />} />
                        </Routes>
                    </div>
                </BrowserRouter>
                <NotificationToast store={this.props.store} />
            </>
        );
    }
}
