import React from "react";
import { observer } from "mobx-react";
import { observable, action, makeObservable } from "mobx";
import Store from "../../libs/store";
import { Notification } from "shared/models-web";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";

@observer
export default class Notifications extends React.Component<{ store: Store }> {
    @observable
    notifications: Notification[] = [];
    @observable
    notifications_count = 0;

    updating = false;

    constructor(props: any) {
        super(props);

        makeObservable(this);

        this.reload();
    }

    componentDidMount = () => {
        this.props.store.on("notifications.event", this.reload);
    };

    componentWillUnmount = () => {
        this.props.store.off("notifications.event", this.reload);
    };

    @action
    reload = async () => {
        if (this.updating) {
            return;
        }

        try {
            this.updating = true;

            const data = (
                await this.props.store.notificationsApi.get({
                    filter: "all",
                    read: false,
                    limit: 5
                })
            ).data;

            this.notifications = data.events;
            this.notifications_count = data.all;
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
            } else {
                console.log("here", `We got unspecified error: ${e}`);
            }
        } finally {
            this.updating = false;
        }
    };

    clearAll = async () => {
        try {
            for (const notification of this.notifications) {
                if (!notification._id) {
                    continue;
                }

                await this.props.store.notificationsApi.mark_read(notification._id);
            }
            await this.reload();
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
            } else {
                console.log("here", `We got unspecified error: ${e}`);
            }
        }
    };

    mark_read = async (notification: Notification) => {
        try {
            if (!notification._id) {
                throw new Error("Can be only used with db notifications");
            }

            await this.props.store.notificationsApi.mark_read(notification._id);
            await this.reload();
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
            } else {
                console.log("here", `We got unspecified error: ${e}`);
            }
        }
    };

    icon = (notification: Notification) => {
        switch (notification.section) {
            case "email":
                return "bi-envelope-fill";
            case "documents":
                return "bi-file-earmark-fill";
        }

        return "bi-egg";
    };

    css_class = (notification: Notification) => {
        switch (notification.type) {
            case "info":
                return "bg-success";
            case "error":
                return "bg-danger";
            case "warn":
                return "bg-warning";
        }

        return "bg-info";
    };

    @action
    handleDownload = async (notification: Notification) => {
        if (notification.subsection === "get_pdfs") {
            const data = await this.props.store.documentsApi.pdfs({ fileId: notification.context.fileId });
            const blob = new Blob([data.data], { type: "application/pdf" });
            const href = window.URL.createObjectURL(blob);

            var link = window.document.createElement("a");

            link.href = href;
            link.download = `${data.headers["filename"]}`;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    render() {
        const notifications = [];
        for (const notification of this.notifications) {
            notifications.push(
                <div key={notification._id} className="d-flex align-items-center py-10px dropdown-item-notifications text-wrap">
                    <div className={`pointer notify-icon ${this.css_class(notification)}`}>
                        <i className={`bi ${this.icon(notification)}`}></i>
                    </div>
                    <div className="flex-1 flex-wrap ps-3">
                        <div className="pointer mb-1 text-white" onClick={() => this.mark_read(notification)}>{notification.title}</div>
                        {(() => {
                            if (notification.subsection === "get_pdfs") {
                                return (
                                    <a href="javascript:;" onClick={() => this.handleDownload(notification)}>
                                        Download
                                    </a>
                                );
                            }

                            return <></>;
                        })()}
                        <div className="small">{moment(notification.time, "X").from(moment())}</div>
                    </div>
                    <a className="ps-2 fs-16px pointer" onClick={() => this.mark_read(notification)}>
                        <i className="bi bi-x"></i>
                    </a>
                </div>
            );
        }

        return (
            <>
                <Dropdown className="menu-item dropdown dropdown-mobile-full">
                    <Dropdown.Toggle className="pointer menu-link" as="a">
                        <div className="menu-icon">
                            <i className="bi bi-bell nav-icon"></i>
                        </div>
                        {this.notifications.length > 0 && <div className="menu-badge bg-theme"></div>}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1 w-300px fs-11px pt-1">
                        <h6 className="dropdown-header fs-10px mb-1">NOTIFICATIONS</h6>
                        <div className="dropdown-divider mt-1"></div>
                        {notifications}

                        <hr className="bg-white-transparent-5 mb-0 mt-2" />
                        <div className="py-10px mb-n2 text-center">
                            <Link to="/notifications" className="pointer text-decoration-none fw-bold">
                                SEE ALL
                            </Link>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </>
        );
    }
}
