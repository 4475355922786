import { LoadDocumentRequest } from "shared/models-web";
import Store from "../store";
import _ from 'lodash';


export async function executeSelectionAction(store: Store, context: any, request: LoadDocumentRequest) {
    switch (context.action) {
        case 'mark-issued-and-send':
            return await processMarkIssuedAndSend(store, request, context);
        default:
            for (const call of context.call) {
                switch (call.function) {
                    case 'issue_selection':
                        await issue_selection(store, request, call.arguments);
                        break;
                    case 'send_selection':
                        await send_selection(store, request, call.arguments);
                        break;
                }
            }
    }
}


export async function issue_selection(store: Store, request: LoadDocumentRequest, args: any = {}) {
    console.log("issue_selection", request);

    if (!request.id) {
        throw new Error("Please provide document id!");
    }

    await store.documentsApi.status(request.id, args.status);
}

export async function send_selection(store: Store, request: LoadDocumentRequest, args: any = {}) {
    console.log("send_selection", request);

    await store.documentsApi.send_bulk(request);
}

async function processMarkIssuedAndSend(store: Store, request: LoadDocumentRequest, context: any) {
    const call = _.find(context.call, (call) => call.function === 'issue_selection');

    if (!call) {
        throw new Error("Missing 'issue_selection' action to be called");
    }

    if (request.ids.length === 0) {
        throw new Error("This tool only works when using selection tool");
    }

    //change statuses
    const data = (await store.documentsApi.status_bulk(request, call.arguments.status)).data;
    const docs = (await store.documentsApi.listFilterted(request)).data;

    //now intersect status errors and documents and get ids to be sent to
    const ids = [];
    for (const doc of docs.documents) {
        const failed = _.find(data, (entry) => entry.documentId === doc.document._id);

        if (failed === undefined) {
            ids.push(doc.document._id);
        }
    }

    if (ids.length > 0) {
        await store.documentsApi.send_bulk({
            ids,
            type: request.type
        });
    }

    return data;
}
