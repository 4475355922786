import _ from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Form, ListGroup } from "react-bootstrap";
import { PermissionEntry, Permissions } from "shared/models-web";

export default observer(function PermissionsEntry(props: { permissions: Permissions; onChange: (entries: { [id: string]: PermissionEntry }) => void }) {
    const [entry, setEntry] = useState({
        name: "",
        abilities: {
            canRead: false,
            canWrite: false,
            canDelete: false,
        },
    });
    const [permissions, setPermissions] = useState(props.permissions);

    useEffect(() => {
        props.onChange(permissions.entries);
    }, [permissions]);

    console.log(permissions, props.permissions)

    return (
        <>
            <ListGroup>
                {_.map(permissions.entries, (item, i) => {
                    return (
                        <ListGroup.Item key={i}>
                            {i} -{" "}
                            <i>
                                R: {item.canRead ? "yes" : "no"}, W: {item.canWrite ? "yes" : "no"}, D: {item.canDelete ? "yes" : "no"}
                            </i>
                            <button
                                className="btn btn-sm btn-link"
                                onClick={() => {
                                    const newPermissions = Object.assign({}, permissions);
                                    newPermissions.entries = _.pickBy(newPermissions.entries, function (value, key) {
                                        return key !== i;
                                    });
                                    setPermissions(newPermissions);
                                }}>
                                <i className="fa fa-fw fa-times" />
                            </button>
                        </ListGroup.Item>
                    );
                })}
            </ListGroup>

            <div className="row mt-3">
                <div className="col-md-6">
                    <Form.Label>Key</Form.Label>
                    <Form.Control
                        type="text"
                        value={entry.name}
                        onChange={(e) => {
                            const newEntry = Object.assign({}, entry);
                            newEntry.name = e.target.value;
                            setEntry(newEntry);
                        }}
                    />
                </div>

                <div className="col">
                    <Form.Label>read</Form.Label>
                    <Form.Check
                        type="checkbox"
                        checked={entry.abilities.canRead}
                        onChange={(e) => {
                            const newEntry = Object.assign({}, entry);
                            newEntry.abilities.canRead = e.target.checked;
                            setEntry(newEntry);
                        }}
                    />
                </div>
                <div className="col">
                    <Form.Label>write</Form.Label>
                    <Form.Check
                        type="checkbox"
                        checked={entry.abilities.canWrite}
                        onChange={(e) => {
                            const newEntry = Object.assign({}, entry);
                            newEntry.abilities.canWrite = e.target.checked;
                            setEntry(newEntry);
                        }}
                    />
                </div>
                <div className="col">
                    <Form.Label>delete</Form.Label>
                    <Form.Check
                        type="checkbox"
                        checked={entry.abilities.canDelete}
                        onChange={(e) => {
                            const newEntry = Object.assign({}, entry);
                            newEntry.abilities.canDelete = e.target.checked;
                            setEntry(newEntry);
                        }}
                    />
                </div>

                <div className="col">
                    <button
                        type="button"
                        className="btn btn-sm btn-outline-theme rounded-0 mt-28"
                        onClick={() => {
                            const newPermissions = Object.assign({}, permissions);
                            if(!newPermissions.entries) {
                                newPermissions.entries = {};
                            }

                            newPermissions.entries[entry.name] = entry.abilities;
                            setPermissions(newPermissions);
                            setEntry({
                                name: "",
                                abilities: {
                                    canRead: false,
                                    canWrite: false,
                                    canDelete: false,
                                },
                            });
                        }}>
                        Add
                    </button>
                </div>
            </div>
        </>
    );
});
