import React from "react";
import { observer } from "mobx-react";
import { Notification } from "shared/models-web";
import Store from "../../libs/store";
import { action, makeObservable, observable, toJS } from "mobx";
import moment from "moment";

@observer
export default class NotificationToast extends React.Component<{ store: Store }> {
    @observable
    notfications: Notification[] = [];

    constructor(props: any) {
        super(props);

        makeObservable(this);
    }

    componentDidMount = () => {
        this.props.store.on("notifications.event", this.handleEvent);
    };

    componentWillUnmount = () => {
        this.props.store.off("notifications.event", this.handleEvent);
    };

    @action
    handleEvent = async (data: Notification) => {
        this.notfications.push(data);

        setTimeout(this.handleTimeer, 5000);
    };

    @action
    handleTimeer = async () => {
        this.notfications.pop();
    };

    @action
    handleDownload = async (notification: Notification) => {
        if (notification.subsection === "get_pdfs") {
            const data = await this.props.store.documentsApi.pdfs({ fileId: notification.context.fileId });
            const blob = new Blob([data.data], { type: "application/pdf" });
            const href = window.URL.createObjectURL(blob);

            var link = window.document.createElement("a");

            link.href = href;
            link.download = `${data.headers["filename"]}`;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    render() {
        const notifications = [];

        for (const notification of this.notfications) {
            notifications.push(
                <div key={notification._id} className="toast fade mb-3 show">
                    <div className="toast-header">
                        <i className="far fa-bell text-muted me-2"></i>
                        <strong className="me-auto">{notification.type}</strong>
                        <small>{moment(notification.time, "X").from(moment())}</small>
                    </div>
                    <div className="toast-body">
                        <p>{notification.title}</p>
                        {(() => {
                            if (notification.subsection === "get_pdfs") {
                                return (
                                    <a href="javascript:;" onClick={() => this.handleDownload(notification)}>
                                        Download
                                    </a>
                                );
                            }

                            return <></>;
                        })()}
                    </div>
                </div>
            );
        }

        return <div className="toasts-container">{notifications}</div>;
    }
}
