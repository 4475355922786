import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Alert } from "react-bootstrap";
import { Location, NavigateFunction, Params } from "react-router-dom";
import Store from "../../libs/store";
import { withRouter } from "../../libs/utils/react_utils";
import { fromBase64, fromBase64url, timeout, toBase64 } from "../../libs/utils/utils";
import path from "path";
import _ from "lodash";
import MonacoEditor from "react-monaco-editor";

@observer
class EditFile extends React.Component<{ store: Store; router: { location: Location; navigate: NavigateFunction; params: Params<string> } }> {
    @observable
    hasError = false;
    @observable
    errorMessage = "";

    @observable
    file: string = "";
    @observable
    fileext: string = "";
    @observable
    content: string = "";
    @observable
    language: string = "html";

    ext2lang = {
        ".css": "css",
        ".scss": "scss",
        ".js": "javascript",
        ".html": "html",
    };

    constructor(props: any) {
        super(props);

        makeObservable(this);

        this.init();
    }

    init = async () => {
        try {
            const rawFileData = this.props.router.params.file;

            if (rawFileData) {
                this.file = fromBase64url(rawFileData);
                const data = (await this.props.store.editorApi.load_file(this.file)).data;

                this.content = fromBase64(data.content);

                this.fileext = path.extname(this.file);
                this.language = _.reduce(
                    this.ext2lang,
                    (res, v, k) => {
                        return this.fileext === k ? v : res;
                    },
                    this.language
                );
            }
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    editorDidMount = (editor: any, monaco: any) => {
        console.log("editorDidMount", editor);
        editor.focus();
    };

    @action
    onChange = (newValue: any, e: any) => {
        // console.log('onChange', newValue, e)
        this.content = newValue;
    };

    @action
    useFileInfo = () => {
        if (this.file !== "") {
            this.language = _.reduce(
                this.ext2lang,
                (res, v, k) => {
                    return this.fileext === k ? v : res;
                },
                this.language
            );
        }
    };

    @action
    save = async () => {
        try {
            this.props.store.editorApi.save_file(this.file, toBase64(this.content));
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    render(): React.ReactNode {
        const options = {
            selectOnLineNumbers: true,
        };

        return (
            <>
                <ul className="breadcrumb">
                    <li className="breadcrumb-item">HOME</li>
                    <li className="breadcrumb-item">SETTINGS</li>
                    <li className="breadcrumb-item">TEMPLATES</li>
                    <li className="breadcrumb-item active">{this.file.toUpperCase()}</li>
                </ul>

                <h1 className="page-header">
                    Configuration <small>all settings in one place</small>
                </h1>

                {this.hasError && <Alert variant="danger">{this.errorMessage}</Alert>}

                <div className="d-flex align-items-center mb-3">
                    <div className="btn-group">
                        <button type="button" className="btn btn-sm btn-outline-theme rounded-0" onClick={() => this.save()}>
                            <i className="fa fa-fw fa-pencil" />
                            &nbsp;
                            <span>Save</span>
                        </button>
                    </div>
                </div>

                <MonacoEditor language={this.language} theme="vs-light" value={this.content} options={options} onChange={this.onChange} editorDidMount={this.editorDidMount} width="100%" height="900px" />
            </>
        );
    }
}

export default withRouter(EditFile);
