import _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Form } from "react-bootstrap";
import { CompanyContact } from "shared/models-web";
import Table from "../../../components/ui/Table";

@observer
export default class ContactEdit extends React.Component<{ contacts: CompanyContact[]; collectData: (contacts: CompanyContact[]) => void  }> {
    @observable
    contacts: CompanyContact[] = [];

    @observable
    contact: CompanyContact = {
        department: "",
        email: [],
    };
    
    //mode 1 == adding, mode 2 == editing
    @observable
    mode = 1;

    constructor(props: any) {
        super(props);

        makeObservable(this);
        this.contacts = props.contacts;
    }

    @action
    addContact = () => {
        if (this.mode === 1) {
            this.contacts.push(this.contact);
        }

        this.contact = {
            department: "",
            email: [],
            name: "",
        };
        this.mode = 1;
        this.props.collectData(this.contacts);
    };

    @action
    edit = (contact: CompanyContact) => {
        this.contact = contact;
        this.mode = 2;
    };

    @action
    delete = (contact: CompanyContact) => {
        this.contacts = this.contacts.filter((item) => item !== contact);

        this.props.collectData(this.contacts);
    };

    render(): React.ReactNode {
        return (
            <>
                <Table
                    columns={[
                        {
                            Header: "Department",
                            accessor: "department",
                        },
                        {
                            Header: "Email",
                            accessor: "email",
                            actions: (row: any) => {
                                return row.original.email.join(",")
                            }
                        },
                        {
                            Header: "Name",
                            accessor: "name",
                        },
                        {
                            Header: "Actions",
                            accessor: "action",
                            actions: (row: any) => {
                                return (
                                    <div className="btn-group btn-group-sm">
                                        <button type="button" className="btn btn-outline-theme btn-sm" onClick={() => this.edit(row.original)}>
                                            Edit
                                        </button>
                                        <button type="button" className="btn btn-outline-theme btn-sm" onClick={() => this.delete(row.original)}>
                                            Delete
                                        </button>
                                    </div>
                                );
                            },
                        },
                    ]}
                    data={this.contacts}
                    showFooter={false}
                    defaultPageSize={500}
                />
                <div className="row mb-3">
                    <div className="col">
                        <Form.Group className="mb-3">
                            <Form.Label>Department</Form.Label>
                            <Form.Control className="form-control-sm" type="text" value={this.contact.department} onChange={action((e) => (this.contact.department = e.target.value))} />
                        </Form.Group>
                    </div>
                    <div className="col">
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control className="form-control-sm" type="email" value={this.contact.email} onChange={action((e) => {
                                this.contact.email = e.target.value.split(",");
                            })} />
                        </Form.Group>
                    </div>
                    <div className="col">
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control className="form-control-sm" type="text" value={this.contact.name} onChange={action((e) => (this.contact.name = e.target.value))} />
                        </Form.Group>
                    </div>
                    <div className="col">
                        <button type="button" className="btn btn-sm btn-outline-theme rounded-0 mt-28" onClick={this.addContact}>
                            {this.mode === 1 && (
                                <>
                                    <i className="fa fa-fw fa-plus" />
                                    &nbsp;
                                    <span>Add</span>
                                </>
                            )}
                            {this.mode === 2 && (
                                <>
                                    <i className="fa fa-fw fa-pencil" />
                                    &nbsp;
                                    <span>Update</span>
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </>
        );
    }
}
