import React from "react";
import { observer } from "mobx-react";
import { observable, action, makeObservable } from "mobx";
import Store from "../../libs/store";
import { Notification } from "shared/models-web";
import { timeout } from "../../libs/utils/utils";
import { Alert } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";

@observer
export default class Notifications extends React.Component<{ store: Store }> {
    @observable
    isLoading = false;
    @observable
    hasMore = false;
    @observable
    filter = "hour";
    @observable
    from = 0;

    @observable
    notifications: Notification[] = [];
    @observable
    notifications_count = 0;

    @observable
    hasError = false;
    @observable
    errorMessage = "";

    constructor(props: any) {
        super(props);

        makeObservable(this);

        this.init();
    }

    @action
    init = async () => {
        await this.loadNotifications();

        // Binds our scroll event handler
        window.onscroll = _.debounce(async () => {
            // Bails early if:
            // * there's an error
            // * it's already loading
            // * there's nothing left to load
            if (this.isLoading || !this.hasMore) return;

            // Checks that the page has scrolled to the bottom
            if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
                this.from = this.notifications.length;

                await this.loadNotifications();
            }
        }, 100);
    };

    componentDidMount = () => {
        this.props.store.on("notifications.event", this.reload);
    };

    componentWillUnmount = () => {
        window.onscroll = null;
        this.props.store.off("notifications.event", this.reload);
    };

    @action
    reload = async () => {
        this.hasMore = true;
        this.from = 0;
        this.notifications = [];

        await this.loadNotifications();
    };

    @action
    loadNotifications = async () => {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;
        try {
            const data = (
                await this.props.store.notificationsApi.get({
                    filter: this.filter,
                    limit: 50,
                    offset: this.from,
                })
            ).data;

            this.notifications = this.notifications.concat(data.events);
            this.notifications_count = data.all;

            this.hasMore = data.hasMore;
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        } finally {
            this.isLoading = false;
        }
    };

    @action
    toggleFilter = async (filter: string) => {
        this.filter = filter;
        this.hasMore = true;
        this.from = 0;
        this.notifications = [];

        await this.loadNotifications();
    };

    icon = (notification: Notification) => {
        switch (notification.section) {
            case "email":
                return "bi-envelope-fill";
            case "documents":
                return "bi-file-earmark-fill";
        }

        return "bi-egg";
    };

    css_class = (notification: Notification) => {
        switch (notification.type) {
            case "info":
                return "bg-success";
            case "error":
                return "bg-danger";
            case "warn":
                return "bg-warning";
        }

        return "bg-info";
    };

    @action
    handleDownload = async (notification: Notification) => {
        if (notification.subsection === "get_pdfs") {
            const data = await this.props.store.documentsApi.pdfs({ fileId: notification.context.fileId });
            const blob = new Blob([data.data], { type: "application/pdf" });
            const href = window.URL.createObjectURL(blob);

            var link = window.document.createElement("a");

            link.href = href;
            link.download = `${data.headers["filename"]}`;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    render() {
        const notifications = [];
        for (const notification of this.notifications) {
            notifications.push(
                <div key={notification._id} className="d-flex align-items-center py-10px text-wrap">
                    <div className={`notify-icon ${this.css_class(notification)}`}>
                        <i className={`bi ${this.icon(notification)}`}></i>
                    </div>
                    <div className="flex-1 flex-wrap ps-3">
                        <div className="mb-1 text-white">
                            {notification.title} ({notification.actor})
                        </div>
                        <div className="mb-1 fw-bold">
                            {notification.text}{" "}
                            {(() => {
                                if (notification.subsection === "get_pdfs") {
                                    return (
                                        <a href="javascript:;" onClick={() => this.handleDownload(notification)}>
                                            Download
                                        </a>
                                    );
                                }

                                return <></>;
                            })()}
                        </div>
                        <div className="small">{moment(notification.time, "X").from(moment())}</div>
                    </div>
                </div>
            );
        }

        return (
            <>
                <ul className="breadcrumb">
                    <li className="breadcrumb-item">HOME</li>
                    <li className="breadcrumb-item">NOTIFICATIONS</li>
                    <li className="breadcrumb-item active">LIST</li>
                </ul>

                <h1 className="page-header">
                    Notifications <small>View the list and more</small>
                </h1>

                {this.hasError && <Alert variant="danger">{this.errorMessage}</Alert>}

                <div className="card">
                    <ul className="nav nav-tabs nav-tabs-v2 px-4">
                        <li className="nav-item me-3">
                            <button className={"nav-link px-2 " + (this.filter === "hour" ? "active" : "")} onClick={() => this.toggleFilter("hour")}>
                                Last hour
                            </button>
                        </li>
                        <li className="nav-item me-3">
                            <button className={"nav-link px-2 " + (this.filter === "day" ? "active" : "")} onClick={() => this.toggleFilter("day")}>
                                Last 24h
                            </button>
                        </li>
                        <li className="nav-item me-3">
                            <button className={"nav-link px-2 " + (this.filter === "all" ? "active" : "")} onClick={() => this.toggleFilter("all")}>
                                Everything
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content p-4">
                        <div className="tab-pane fade show active" id="allTab">
                            {notifications}
                        </div>
                    </div>
                    <div className="card-arrow">
                        <div className="card-arrow-top-left"></div>
                        <div className="card-arrow-top-right"></div>
                        <div className="card-arrow-bottom-left"></div>
                        <div className="card-arrow-bottom-right"></div>
                    </div>
                </div>
            </>
        );
    }
}
