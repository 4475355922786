import { PermissionEntry, User } from "shared/models-web";
import Api from "../utils/api";


export default class UsersApi extends Api {
    private static PREFIX = "/api/v1/users";

    list = async () => {
        return (await this.axios.get(`${UsersApi.PREFIX}/list`)).data;
    }

    load = async (id: string) => {
        return (await this.axios.get(`${UsersApi.PREFIX}/${id}/load`)).data;
    }

    add_user = async (user: User, passowrd: {
        passwordExisting?: string
        passwordNew?: string
        passwordRepeated?: string
    }) => {
        return (await this.axios.post(`${UsersApi.PREFIX}/new/save`, {
            user,
            passowrd
        })).data;
    }

    update_user = async (id: string, user: User, passowrd: {
        passwordExisting?: string
        passwordNew?: string
        passwordRepeated?: string
    }) => {
        return (await this.axios.post(`${UsersApi.PREFIX}/${id}/save`, {
            user,
            passowrd
        })).data;
    }

    check_permission = async (userId: string, permission: string) => {
        return (await this.axios.post(`${UsersApi.PREFIX}/${userId}/permission/check`, {
            permission
        })).data;
    }

    list_premission = async (userId: string) => {
        return (await this.axios.get(`${UsersApi.PREFIX}/${userId}/permission/list`)).data;
    }

    add_premission = async (userId: string, permission: string, entry: PermissionEntry) => {
        return (await this.axios.post(`${UsersApi.PREFIX}/${userId}/permission/add`, {
            permission,
            entry
        })).data;
    }

    update_premission = async (userId: string, permission: string, entry: PermissionEntry) => {
        return (await this.axios.post(`${UsersApi.PREFIX}/${userId}/permission/update`, {
            permission,
            entry
        })).data;
    }

    remove_premission = async (userId: string, permission: string) => {
        return (await this.axios.post(`${UsersApi.PREFIX}/${userId}/permission/remove`, {
            permission
        })).data;
    }
    
}