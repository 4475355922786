import { action, makeObservable, observable, toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Alert, Col, Dropdown, Form, InputGroup, Row } from "react-bootstrap";
import { Company, DocumentData, Material, TaxRate, TypedDocument, Units } from "shared/models-web";
import Store from "../../libs/store";
import { getJSONFromArraBuffer, timeout } from "../../libs/utils/utils";
import { withRouter } from "../../libs/utils/react_utils";
import { Location, NavigateFunction, Params } from "react-router-dom";
import Popup from "../../components/ui/Popup";
import _ from "lodash";
import moment from "moment";
import DocumentEdit from "./DocumentEdit";
import SelectableTable, { FilterOptions } from "../../components/ui/SelectableTable";
import { executeAdditionalActions, executeSelectionAction, getSelectionActions } from "../../libs/extra";
import PopupSimple from "../../components/ui/PopupSimple";
import { printf } from "fast-printf";

type SendType = "single" | "multiple";

@observer
class Documents extends React.Component<{ store: Store; router: { location: Location; navigate: NavigateFunction; params: Params<string> } }> {
    @observable
    private data: DocumentData[] = [];
    private refreshTable?: () => void;

    @observable
    filters: { [id: string]: FilterOptions } = {
        "document.name": {
            name: "ID",
            type: "range",
            value: [],
            valueRaw: [],
            match: (term: string) => {
                let matched;
                if ((matched = term.match(/^(.*)-(.*)$/)) !== null) {
                    const [m, year, res] = matched;

                    return res;
                }

                return undefined;
            },
        },
    };

    @observable
    hasError = false;
    @observable
    errorMessage = "";

    @observable
    hasWarning = false;
    @observable
    warningMessage = "";

    private type?: string;

    @observable
    popupHasError = false;
    @observable
    popupErrorMessage = "";

    @observable
    showStatusChangePopup = false;
    @observable
    status?: string = "";
    @observable
    documentId?: string = "";

    @observable
    filterStatus?: string = "draft";

    @observable
    isProcessingPDF = false;
    @observable
    processingPDFDocumentId = "";

    @observable
    isProcessingEmail = false;
    @observable
    processingEmailDocumentId = "";

    @observable
    showEmailSelectPopup = false;
    @observable
    selectedEmail?: string = "";
    @observable
    selectedEmailNote?: string = "";
    @observable
    selectedDocument?: string = "";
    @observable
    sendType: SendType = "single";

    @observable
    showMoveDocumentPopup = false;
    @observable
    selectedMoveDocumentId?: string = "";
    @observable
    moveDocumentType: string = "";
    @observable
    moveDocumentStatus: string = "";

    @observable
    showMoveDocumentsPopup = false;
    @observable
    showMoveMessage: number = 0;

    @observable
    showIssueAndSendReport = false;
    @observable
    issueAndSendReport: { documentId: string; status: string; error: string }[] = [];

    @observable
    selectedYear = Number.parseInt(moment().format("YYYY"));
    @observable
    selectedMonth?: string;
    selectedDocuments: { original: DocumentData }[] = [];

    // editing/adding documents
    @observable
    editTitle = "";
    @observable
    editShow = false;
    @observable
    editIsDisabled = false;
    @observable
    editSelectedDocument?: TypedDocument;
    editDocumentInformer?: (document: TypedDocument) => void;

    @observable
    compaines: Company[] = [];
    @observable
    units: Units[] = [];
    @observable
    materials: Material[] = [];
    @observable
    taxRates: TaxRate[] = [];

    constructor(props: any) {
        super(props);

        this.loadDocuments();
        this.init();
        makeObservable(this);
    }

    @action
    init = async () => {
        try {
            this.compaines = (await this.props.store.companiesApi.list()).data.companies;
            this.units = (await this.props.store.codetablesApi.list_unit()).data.units;
            this.materials = (await this.props.store.codetablesApi.list_material()).data.materials;
            this.taxRates = (await this.props.store.codetablesApi.list_taxrate()).data.taxRates;
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    @action
    loadDocuments = async () => {
        try {
            if (!this.props.router.params.type) {
                throw new Error("Missing type");
            }

            this.type = this.props.router.params.type;

            const data = await this.props.store.documentsApi.listFilterted({
                type: this.props.router.params.type,
                status: this.filterStatus,
                year: this.selectedYear.toString(),
                month: this.selectedMonth ? this.selectedMonth.toLocaleLowerCase() : undefined,
                ids: [],
            });

            this.data = data.data.documents;
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    componentDidMount = () => {
        this.props.store.on("reload.documents", this.trigger_rescan);
    };

    componentWillUnmount = () => {
        this.props.store.off("reload.documents", this.trigger_rescan);
    };

    componentDidUpdate() {
        if (this.type !== this.props.router.params.type) {
            this.loadDocuments();
        }
    }

    trigger_rescan = async () => {
        this.loadDocuments();
    };

    @action
    onCloseStatusChangePopup = () => {
        this.showStatusChangePopup = false;
    };

    @action
    onSaveStatusChangePopup = async () => {
        try {
            if (!this.status) {
                throw new Error("Please select status");
            }

            if (this.documentId) {
                await this.props.store.documentsApi.status(this.documentId, this.status);
            } else {
                this.issueAndSendReport = (
                    await this.props.store.documentsApi.status_bulk(
                        {
                            type: this.type,
                            ids: _.map(this.selectedDocuments, (item) => {
                                return item.original.document._id ? item.original.document._id : "x";
                            }),
                        },
                        this.status
                    )
                ).data;

                console.log("here", toJS(this.issueAndSendReport));

                if (this.issueAndSendReport.length > 0) {
                    this.showIssueAndSendReport = true;
                }
            }

            this.showStatusChangePopup = false;

            await this.loadDocuments();

            this.refreshTable && this.refreshTable();
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.popupErrorMessage = e.response.data.errMsg;
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        }
    };

    @action
    onOpenChangeStatusPopup = async (id?: string) => {
        this.showStatusChangePopup = true;

        if (id) {
            const d = _.find(this.data, { document: { _id: id } });

            if (!d) {
                this.popupErrorMessage = "Can't load document";
                this.popupHasError = true;

                await timeout(2000);
                this.popupHasError = false;
                return;
            }

            this.status = d.document.status;
            this.documentId = id;
        } else {
            this.status = undefined;
            this.documentId = undefined;
        }
    };

    @action
    onOpenEmailSelectPopup = async (id?: string, email?: string, sendType?: SendType) => {
        this.showEmailSelectPopup = true;
        this.selectedEmail = email;
        this.selectedDocument = id;

        if (sendType) {
            this.sendType = sendType;
        } else if (id) {
            this.sendType = "single";
        } else {
            this.sendType = "multiple";
        }
    };

    @action
    onCloseEmailSelectPopup = () => {
        this.showEmailSelectPopup = false;
    };

    @action
    onCreateCSV = async () => {
        try {
            if (!this.type) {
                throw new Error("Type missing");
            }

            const data = await this.props.store.documentsApi.csv({
                type: this.props.router.params.type,
                status: this.filterStatus,
                year: this.selectedYear.toString(),
                month: this.selectedMonth ? this.selectedMonth.toLocaleLowerCase() : undefined,
                ids: _.map(this.selectedDocuments, (item) => {
                    return item.original.document._id ? item.original.document._id : "x";
                }),
            });

            var link = window.document.createElement("a");
            var csv = new Blob([data.data], { type: "text/csv" });

            link.href = window.URL.createObjectURL(csv);
            link.download = `${data.headers["filename"]}`;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                const data = getJSONFromArraBuffer(e.response.data);
                console.log("here", data);
                this.errorMessage = data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    @action
    onCreateXML = async () => {
        try {
            if (!this.type) {
                throw new Error("Type missing");
            }

            const data = await this.props.store.documentsApi.vod_xml({
                type: this.props.router.params.type,
                status: this.filterStatus,
                year: this.selectedYear.toString(),
                month: this.selectedMonth ? this.selectedMonth.toLocaleLowerCase() : undefined,
                ids: _.map(this.selectedDocuments, (item) => {
                    return item.original.document._id ? item.original.document._id : "x";
                }),
            });
            const blob = new Blob([data.data], { type: "text/xml" });
            const href = window.URL.createObjectURL(blob);

            // window.open(href);

            const link = window.document.createElement("a");
            link.href = href;
            link.download = `${data.headers["filename"]}`;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                const data = getJSONFromArraBuffer(e.response.data);
                console.log("here", data);
                this.errorMessage = data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    @action
    onCreatePDF = async (id: string, popup = false) => {
        try {
            this.isProcessingPDF = true;
            this.processingPDFDocumentId = id;

            const data = await this.props.store.documentsApi.pdf(id);
            const blob = new Blob([data.data], { type: "application/pdf" });
            const href = window.URL.createObjectURL(blob);

            if (popup) {
                window.open(href);
            } else {
                var link = window.document.createElement("a");

                link.href = href;
                link.download = `${data.headers["filename"]}`;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                const data = getJSONFromArraBuffer(e.response.data);
                console.log("here", data);
                this.errorMessage = data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        } finally {
            this.isProcessingPDF = false;
        }
    };

    @action
    onCreatePDFs = async () => {
        try {
            const data = await this.props.store.documentsApi.pdfs_create({
                type: this.props.router.params.type,
                status: this.filterStatus,
                year: this.selectedYear.toString(),
                month: this.selectedMonth ? this.selectedMonth.toLocaleLowerCase() : undefined,
                ids: _.map(this.selectedDocuments, (item) => {
                    return item.original.document._id ? item.original.document._id : "x";
                }),
            });

            this.warningMessage = data.data.wrnMsg;
            this.hasWarning = true;

            await timeout(2000);
            this.hasWarning = false;
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        } finally {
            this.isProcessingPDF = false;
        }
    }

    @action
    onSendDocuments = async () => {
        try {
            this.isProcessingEmail = true;

            if (!this.selectedEmail) {
                throw new Error("Missing email contact");
            }

            if (!this.type) {
                throw new Error("Type missing");
            }

            if (this.sendType === "single") {
                if (!this.selectedDocument) {
                    throw new Error("Code error wrong sendType");
                }

                await executeAdditionalActions(this.props.store, "mark-issued", {
                    id: this.selectedDocument,
                    ids: [],
                });

                await this.props.store.documentsApi.send(this.selectedDocument, this.selectedEmail, this.selectedEmailNote);
            } else {
                await this.props.store.documentsApi.send_multiple({
                    email: this.selectedEmail,
                    loadDocument: {
                        type: this.type,
                        status: this.filterStatus,
                        year: this.selectedYear.toString(),
                        month: this.selectedMonth ? this.selectedMonth.toLocaleLowerCase() : undefined,
                        ids: _.map(this.selectedDocuments, (item) => {
                            return item.original.document._id ? item.original.document._id : "x";
                        }),
                    },
                });
            }

            await this.loadDocuments();

            this.refreshTable && this.refreshTable();
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;
            this.showEmailSelectPopup = false;

            await timeout(2000);
            this.hasError = false;
        } finally {
            this.showEmailSelectPopup = false;
            this.isProcessingEmail = false;
        }
    };

    @action
    selectStatus = async (status?: string) => {
        this.filterStatus = status;
        await this.loadDocuments();
    };

    @action
    selectYear = async (year: number) => {
        this.selectedYear = year;
        await this.loadDocuments();
    };

    @action
    selectMonth = async (month?: string) => {
        this.selectedMonth = month;
        await this.loadDocuments();
    };

    @action
    editOnOpenPopup = async (selectedDocument?: TypedDocument) => {
        if (selectedDocument) {
            this.editTitle = `Edit document: ${this.type}`;
            this.editSelectedDocument = selectedDocument;
        } else {
            this.editTitle = `Add new document: ${this.type}`;
            this.editSelectedDocument = undefined;
        }

        this.editShow = true;
    };

    @action
    editOnClosePopup = () => {
        this.editShow = false;
    };

    @action
    editOnSaveDocument = async () => {
        try {
            if (!this.editSelectedDocument) {
                throw new Error("Invalid company object");
            }

            if (this.editSelectedDocument.contactId === "-1") {
                throw new Error("Please select contact");
            }

            if (!this.editSelectedDocument._id) {
                await this.props.store.documentsApi.add(this.editSelectedDocument);
            } else {
                await this.props.store.documentsApi.update(this.editSelectedDocument._id, this.editSelectedDocument);
            }

            await this.loadDocuments();

            this.refreshTable && this.refreshTable();

            this.editShow = false;
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.popupErrorMessage = e.response.data.errMsg;
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        }
    };

    @action
    onSelectionActionClick = async (selectionAction: string) => {
        try {
            //should we ask
            this.issueAndSendReport = await executeSelectionAction(this.props.store, selectionAction, {
                type: this.type,
                ids: _.map(this.selectedDocuments, (item) => {
                    return item.original.document._id ? item.original.document._id : "x";
                }),
            });

            if (this.issueAndSendReport.length > 0) {
                this.showIssueAndSendReport = true;
            }

            await this.loadDocuments();

            this.refreshTable && this.refreshTable();
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;
            this.showEmailSelectPopup = false;

            await timeout(2000);
            this.hasError = false;
        }
    };

    @action
    onOpenMoveDocumentsPopup = async () => {
        try {
            if (this.selectedDocuments.length === 0) {
                throw new Error("Please select documents");
            }

            this.showMoveDocumentsPopup = true;

            const result = _.find(this.props.store.settings.documents.types, (o) => o.id !== this.type);

            if (result) {
                this.moveDocumentType = result.id;
            }

            this.showMoveMessage = 0;
            for (const entry of this.selectedDocuments) {
                if (entry.original.document.status === "draft") {
                    this.showMoveMessage |= 1;
                } else {
                    this.showMoveMessage |= 2;
                }
            }
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;
            this.showEmailSelectPopup = false;

            await timeout(2000);
            this.hasError = false;
        }
    };

    @action
    onCloseMoveDocumentsPopup = () => {
        this.showMoveDocumentsPopup = false;
    };

    @action
    onMoveDocuments = async () => {
        try {
            if (this.selectedDocuments.length === 0) {
                throw new Error("Please select documents");
            }

            await this.props.store.documentsApi.move_bulk(
                _.map(this.selectedDocuments, (item) => {
                    return item.original.document._id ? item.original.document._id : "x";
                }),
                this.moveDocumentType
            );
            this.showMoveDocumentsPopup = false;

            await this.loadDocuments();

            this.refreshTable && this.refreshTable();
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;
            this.showMoveDocumentsPopup = false;

            await timeout(2000);
            this.hasError = false;
        }
    };

    @action
    onOpenMoveDocumentPopup = (documentId: string, status: string) => {
        this.showMoveDocumentPopup = true;
        this.moveDocumentStatus = status;
        this.selectedMoveDocumentId = documentId;

        const result = _.find(this.props.store.settings.documents.types, (o) => o.id !== this.type);

        if (result) {
            this.moveDocumentType = result.id;
        }
    };

    @action
    onCloseMoveDocumentPopup = () => {
        this.showMoveDocumentPopup = false;
    };

    @action
    onMoveDocument = async () => {
        try {
            if (!this.selectedMoveDocumentId) {
                throw new Error("Please provide document ....");
            }

            await this.props.store.documentsApi.move(this.selectedMoveDocumentId, this.moveDocumentType);
            this.showMoveDocumentPopup = false;

            await this.loadDocuments();

            this.refreshTable && this.refreshTable();
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;
            this.showMoveDocumentPopup = false;

            await timeout(2000);
            this.hasError = false;
        }
    };

    @action
    onRefresh = async () => {
        try {
            await this.loadDocuments();

            this.refreshTable && this.refreshTable();
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;
            this.showMoveDocumentPopup = false;

            await timeout(2000);
            this.hasError = false;
        }
    };

    render(): React.ReactNode {
        console.log("this.isProcessingPDF", this.isProcessingPDF);
        console.log("this.isProcessingEmail", this.isProcessingEmail);

        const navTabs = [];
        for (const status of this.props.store.settings.documents.statusesOrder) {
            navTabs.push(
                <li className="nav-item me-3">
                    <button className={"nav-link px-2 " + (this.filterStatus === status ? "active" : "")} onClick={() => this.selectStatus(status)}>
                        {status}
                    </button>
                </li>
            );
        }

        const yearSelectItems = [];
        const todaysYear = Number.parseInt(moment().format("YYYY"));
        for (let i = this.props.store.settings.documents.yearRange.from; i <= todaysYear; i++) {
            yearSelectItems.push(
                <Dropdown.Item onClick={() => this.selectYear(i)} eventKey={i}>
                    {i}
                </Dropdown.Item>
            );
        }

        const monthSelectItems = [];
        monthSelectItems.push(
            <Dropdown.Item onClick={() => this.selectMonth(undefined)} eventKey={-1}>
                Any month
            </Dropdown.Item>
        );
        for (let i = 1; i <= 12; i++) {
            const month = moment(i > 9 ? i : `0${i}`, "MM").format("MMMM");
            monthSelectItems.push(
                <Dropdown.Item onClick={() => this.selectMonth(month)} eventKey={i}>
                    {month}
                </Dropdown.Item>
            );
        }

        const selectionActions = [];
        const sa = getSelectionActions(this.props.store) as any;
        for (const i in sa) {
            const entry = sa[i];

            selectionActions.push(
                <button type="button" className="btn btn-sm btn-outline-theme rounded-0" onClick={() => this.onSelectionActionClick(i)}>
                    <i className={"fa fa-fw " + entry.icon} />
                    &nbsp;
                    <span>{entry.title}</span>
                </button>
            );
        }

        const issueAndSendReport = [];
        if (Array.isArray(this.issueAndSendReport)) {
            for (const report of this.issueAndSendReport) {
                const document = _.find(this.data, (data) => data.document._id === report.documentId);
                let doc = report.documentId;

                if (document) {
                    doc = `${document.document.name ? document.document.name : "no number"} - ${document.document.about} - ${document.company?.name}`;
                }

                issueAndSendReport.push(
                    <div className="widget-reminder-item">
                        <div className="widget-reminder-content">
                            <div className="fw-bold">{doc}</div>
                            <div className="fs-13px">{report.error}</div>
                        </div>
                    </div>
                );
            }
        }

        return (
            <>
                <ul className="breadcrumb">
                    <li className="breadcrumb-item">HOME</li>
                    <li className="breadcrumb-item">DOCUMENTS</li>
                    <li className="breadcrumb-item active">{this.props.router.params.type?.toUpperCase()}</li>
                </ul>

                <h1 className="page-header">
                    Documents <small>View the list and more</small>
                </h1>

                {this.hasWarning && <Alert variant="warning">{this.warningMessage}</Alert>}
                {this.hasError && <Alert variant="danger">{this.errorMessage}</Alert>}

                <div className="d-flex align-items-center mb-3">
                    <div className="btn-group">
                        <button type="button" className="btn btn-sm btn-outline-theme rounded-0" onClick={() => this.editOnOpenPopup()}>
                            <i className="fa fa-fw fa-plus" />
                            &nbsp;
                            <span>Add</span>
                        </button>
                        <button type="button" className="btn btn-sm btn-outline-theme rounded-0" onClick={this.onCreateCSV}>
                            <i className="fa fa-fw fa-file-excel" />
                            &nbsp;
                            <span>CSV</span>
                        </button>
                        <button type="button" className="btn btn-sm btn-outline-theme rounded-0" onClick={this.onCreateXML}>
                            <i className="fa fa-fw fa-file-alt" />
                            &nbsp;
                            <span>XML</span>
                        </button>
                        <button type="button" className="btn btn-sm btn-outline-theme rounded-0" onClick={this.onCreatePDFs}>
                            <i className="fa fa-fw fa-file-pdf" />
                            &nbsp;
                            <span>PDFs</span>
                        </button>
                        <button type="button" className="btn btn-sm btn-outline-theme rounded-0" onClick={() => this.onOpenEmailSelectPopup(undefined, undefined, "multiple")}>
                            <i className="fa fa-fw fa-envelope" />
                            &nbsp;
                            <span>Send CSV</span>
                        </button>
                        <Dropdown>
                            <Dropdown.Toggle as={"button"} className="btn btn-sm btn-outline-theme btn-ingroup rounded-0" id="selectedYear">
                                {this.selectedYear}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>{yearSelectItems}</Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Toggle as={"button"} className="btn btn-sm btn-outline-theme btn-ingroup rounded-0" id="selectedMonth">
                                {this.selectedMonth ? this.selectedMonth : "Any month"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>{monthSelectItems}</Dropdown.Menu>
                        </Dropdown>
                        <button type="button" className="btn btn-sm btn-outline-theme rounded-0" onClick={() => this.onOpenMoveDocumentsPopup()}>
                            <i className="fa fa-fw fa-arrow-right" />
                            &nbsp;
                            <span>Move or Clone multiple</span>
                        </button>
                        <button type="button" className="btn btn-sm btn-outline-theme rounded-0" onClick={() => this.onOpenChangeStatusPopup()}>
                            <i className="fa fa-fw fa-exchange-alt" />
                            &nbsp;
                            <span>Change status multipe</span>
                        </button>
                        {selectionActions}
                        <button type="button" className="btn btn-sm btn-outline-theme rounded-0" onClick={() => this.onRefresh()}>
                            <i className="fa fa-fw fa-redo" />
                            &nbsp;
                            <span>Refresh</span>
                        </button>
                    </div>
                </div>

                <div className="card">
                    <ul className="nav nav-tabs nav-tabs-v2 px-4">
                        <li className="nav-item me-3">
                            <button className={"nav-link px-2 " + (this.filterStatus === "all" ? "active" : "")} onClick={() => this.selectStatus("all")}>
                                all
                            </button>
                        </li>
                        {navTabs}
                    </ul>
                    <div className="tab-content p-4">
                        <div className="tab-pane fade show active" id="allTab">
                            {/* <div className="card-body"> */}
                            <SelectableTable
                                columns={[
                                    {
                                        Header: "ID",
                                        accessor: "document.name",
                                        disableSortBy: false,
                                        filter: "stringRangeFilter",
                                    },
                                    {
                                        Header: "Month",
                                        accessor: "document.month",
                                        disableSortBy: false,
                                    },
                                    {
                                        Header: "Year",
                                        accessor: "document.year",
                                        disableSortBy: false,
                                    },
                                    {
                                        Header: "For Company",
                                        accessor: "company.name",
                                        disableSortBy: false,
                                    },
                                    {
                                        Header: "Price",
                                        accessor: "expand_idents.invoiceOverview.total",
                                        disableSortBy: false,
                                        actions: (row) => {
                                            return (
                                                <>
                                                    {printf("%.2f", row.original.expand_idents.invoiceOverview.total).toString().replace(".", ",")} {this.props.store.settings.money.symbol}
                                                </>
                                            );
                                        },
                                        Footer: (info) => {
                                            // Only calculate total visits if rows change
                                            const total = React.useMemo(() => info.rows.reduce((sum, row: any) => row.original.expand_idents.invoiceOverview.total + sum, 0), [info.rows]);

                                            return (
                                                <>
                                                    {printf("%.2f", total).toString().replace(".", ",")} {this.props.store.settings.money.symbol}
                                                </>
                                            );
                                        },
                                    },
                                    {
                                        Header: "PO#",
                                        accessor: "document.poNumber",
                                        disableSortBy: false,
                                    },
                                    {
                                        Header: "About",
                                        accessor: "document.about",
                                        disableSortBy: false,
                                    },
                                    {
                                        Header: "Contact name",
                                        accessor: "contact.name",
                                        disableSortBy: false,
                                    },
                                    {
                                        Header: "Contact email",
                                        accessor: "contact.email",
                                        disableSortBy: false,
                                        actions: (row: any) => {
                                            if (!row.original.contact) {
                                                return <>Missing contact</>;
                                            }

                                            return <>{row.original.contact.email.join(",")}</>;
                                        },
                                    },
                                    {
                                        Header: "Status",
                                        accessor: "document.status",
                                        disableSortBy: false,
                                    },
                                    {
                                        Header: "Sent",
                                        accessor: "isSent",
                                        disableSortBy: false,
                                        actions: (row: any) => {
                                            return <>{row.original.isSent ? "Yes" : "No"}</>;
                                        },
                                    },
                                    {
                                        Header: "Actions",
                                        accessor: "action",
                                        disableSortBy: true,
                                        actions: (row: any) => {
                                            return (
                                                <div className="btn-group btn-group-sm">
                                                    <button type="button" className="btn btn-outline-theme btn-sm" onClick={() => this.editOnOpenPopup(row.original.document)}>
                                                        Edit
                                                    </button>
                                                    <button type="button" className="btn btn-outline-theme btn-sm" onClick={() => this.onOpenChangeStatusPopup(row.original.document._id)}>
                                                        Change status
                                                    </button>
                                                    <button type="button" className="btn btn-outline-theme btn-sm" onClick={() => this.onCreatePDF(row.original.document._id, row.original.document.status === "draft")} disabled={this.isProcessingPDF}>
                                                        {this.processingPDFDocumentId !== row.original.document._id && <i className="fa fa-fw fa-file-pdf" />}
                                                        {this.processingPDFDocumentId === row.original.document._id ? this.isProcessingPDF ? <i className="fa fa-fw fa-spin fa-circle-notch" /> : <i className="fa fa-fw fa-file-pdf" /> : ""}
                                                        &nbsp; PDF
                                                    </button>
                                                    <button type="button" className="btn btn-outline-theme btn-sm" onClick={() => this.onOpenEmailSelectPopup(row.original.document._id, row.original.contact.email, "single")} disabled={this.isProcessingEmail}>
                                                        {this.processingEmailDocumentId !== row.original.document._id && <i className="fa fa-fw fa-envelope" />}
                                                        {this.processingEmailDocumentId === row.original.document._id ? this.isProcessingEmail ? <i className="fa fa-fw fa-spin fa-circle-notch" /> : <i className="fa fa-fw fa-envelope" /> : ""}
                                                        &nbsp; Send
                                                    </button>
                                                    <button type="button" className="btn btn-outline-theme btn-sm" onClick={() => this.onOpenMoveDocumentPopup(row.original.document._id, row.original.document.status)}>
                                                        {row.original.document.status === "draft" && (
                                                            <>
                                                                <i className="fa fa-fw fa-arrow-right" />
                                                                &nbsp; Move
                                                            </>
                                                        )}
                                                        {row.original.document.status !== "draft" && (
                                                            <>
                                                                <i className="fa fa-fw fa-clone" />
                                                                &nbsp; Clone as
                                                            </>
                                                        )}
                                                    </button>
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                data={this.data}
                                onSelected={(selected: any) => {
                                    this.selectedDocuments = selected;
                                }}
                                setUpdater={(func) => (this.refreshTable = func)}
                                tableOptions={{
                                    search: true,
                                    filter: true,
                                }}
                                filters={this.filters}
                            />
                        </div>
                    </div>
                    <div className="card-arrow">
                        <div className="card-arrow-top-left"></div>
                        <div className="card-arrow-top-right"></div>
                        <div className="card-arrow-bottom-left"></div>
                        <div className="card-arrow-bottom-right"></div>
                    </div>
                </div>

                <Popup title="Select new status " show={this.showStatusChangePopup} onClose={this.onCloseStatusChangePopup} onSave={this.onSaveStatusChangePopup}>
                    <Alert show={this.popupHasError} variant="danger">
                        {this.popupErrorMessage}
                    </Alert>

                    <Form.Group className="mb-3" controlId="calculationRef">
                        <Form.Label>Change status</Form.Label>
                        <Form.Select placeholder="Change status" value={this.status} onChange={action((e) => (this.status = e.target.value))}>
                            <option>Select ...</option>

                            {_.map(this.props.store.settings.documents.statusesOrder, (entry, index) => {
                                return (
                                    <option key={entry} value={entry}>
                                        {entry}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>
                </Popup>

                <Popup
                    title="Select email address"
                    show={this.showEmailSelectPopup}
                    onClose={this.onCloseEmailSelectPopup}
                    onSave={this.onSendDocuments}
                    save={
                        <>
                            {this.isProcessingEmail ? <i className="fa fa-fw fa-spin fa-circle-notch" /> : <i className="fa fa-fw fa-envelope" />}
                            &nbsp; Send
                        </>
                    }
                    disabled={this.isProcessingEmail}>
                    <Form.Group className="mb-3">
                        <Form.Label>E-mail address</Form.Label>
                        <Form.Control type="email" placeholder="Email" value={this.selectedEmail} onChange={action((e) => (this.selectedEmail = e.target.value))} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Message</Form.Label>
                        <textarea className="form-control" onChange={action((e: any) => (this.selectedEmailNote = e.target.value))}>
                            {this.selectedEmailNote}
                        </textarea>
                    </Form.Group>
                </Popup>

                <Popup title={this.editTitle} show={this.editShow} onClose={this.editOnClosePopup} onSave={this.editOnSaveDocument} disabled={this.editIsDisabled} size="xl">
                    <DocumentEdit store={this.props.store} document={this.editSelectedDocument} popupHasError={this.popupHasError} popupErrorMessage={this.popupErrorMessage} type={this.type} compaines={this.compaines} units={this.units} materials={this.materials} taxRates={this.taxRates} onChange={action((document) => (this.editSelectedDocument = document))} getInformer={(informer) => (this.editDocumentInformer = informer)} />
                </Popup>

                <Popup title="Move document" show={this.showMoveDocumentPopup} onClose={this.onCloseMoveDocumentPopup} onSave={this.onMoveDocument} save="Move">
                    {this.moveDocumentStatus === "draft" && <h3>You are about to move document to different type</h3>}
                    {this.moveDocumentStatus !== "draft" && <h3>You are about to clone document to different type</h3>}
                    <Form.Group className="mb-3" controlId="calculationRef">
                        <Form.Label>Document type</Form.Label>
                        <Form.Select placeholder="Document type" value={this.moveDocumentType} onChange={action((e) => (this.moveDocumentType = e.target.value))}>
                            <option>Select ...</option>

                            {_.map(this.props.store.settings.documents.types, (entry, index) => {
                                return (
                                    <option key={entry.id} value={entry.id}>
                                        {entry.name}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>
                </Popup>

                <Popup title="Move documents" show={this.showMoveDocumentsPopup} onClose={this.onCloseMoveDocumentsPopup} onSave={this.onMoveDocuments} save="Move">
                    {this.showMoveMessage === 1 && <h3>You are about to move documents to different type</h3>}
                    {this.showMoveMessage === 2 && <h3>You are about to clone documents to different type</h3>}
                    {this.showMoveMessage === 3 && <h3>You are about to clone or move documents to different type</h3>}
                    <Form.Group className="mb-3" controlId="calculationRef">
                        <Form.Label>Document type</Form.Label>
                        <Form.Select placeholder="Document type" value={this.moveDocumentType} onChange={action((e) => (this.moveDocumentType = e.target.value))}>
                            <option>Select ...</option>

                            {_.map(this.props.store.settings.documents.types, (entry, index) => {
                                return (
                                    <option key={entry.id} value={entry.id}>
                                        {entry.name}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>
                </Popup>

                <PopupSimple
                    title="Document errors repor"
                    show={this.showIssueAndSendReport}
                    onClose={action(() => {
                        this.showIssueAndSendReport = false;
                        this.issueAndSendReport = [];
                    })}>
                    <div className="card">
                        <div className="card-header fw-bold">Document errors report</div>
                        <div className="widget-reminder">{issueAndSendReport}</div>

                        <div className="card-arrow">
                            <div className="card-arrow-top-left"></div>
                            <div className="card-arrow-top-right"></div>
                            <div className="card-arrow-bottom-left"></div>
                            <div className="card-arrow-bottom-right"></div>
                        </div>
                    </div>
                </PopupSimple>
            </>
        );
    }
}

export default withRouter(Documents);
