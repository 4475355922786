import EventEmitter from "events";
import Store from "../store";


export default abstract class Session<T> extends EventEmitter {
    protected store: Store;
    public user_id?: string;

    constructor(store: Store) {
        super();

        this.store = store;    
    }

    abstract init_session(): Promise<void>;
    abstract update_session(input?: T): Promise<boolean>;
    abstract get_session(): Promise<T>;
    abstract is_valid(): Promise<boolean>;
}