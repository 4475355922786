import { action, makeObservable, observable, toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import Store from "../../libs/store";

@observer
export default class FolderErrors extends React.Component<{ store: Store }> {
    @observable
    private current_errors: {
        [id: string]: {
            section: string;
            matcher: string;
            text: string;
            file?: string;
            matchedOrder?: string[];
            rawProject?: string;
        }[];
    } = {};
    @observable
    hasError = false;
    @observable
    errorMessage = "";

    constructor(props: any) {
        super(props);

        makeObservable(this);
    }

    componentDidMount = () => {
        this.props.store.on("folders.error", this.onData);
    };

    componentWillUnmount = () => {
        this.props.store.off("folders.error", this.onData);
    };

    @action
    onData = async (data: {
        company_blacklist: string[];
        current_errors: {
            [id: string]: {
                section: string;
                matcher: string;
                text: string;
                file?: string;
                matchedOrder?: string[];
                rawProject?: string;
            }[];
        };
    }) => {
        this.current_errors = data.current_errors;
    };

    render(): React.ReactNode {
        const items = [];
        for (const i in this.current_errors) {
            const entry = this.current_errors[i];

            if (entry.length === 0) {
                continue;
            }

            // console.log(i, toJS(entry));

            const subErrors = [];
            for (const error of entry) {
                subErrors.push(
                    <tr key={error.text}>
                        <td>
                            <span className="d-flex align-items-center">
                                <i className="bi bi-circle-fill fs-6px text-theme me-2"></i>
                                {error.text}
                                {error.matchedOrder && (
                                    <>
                                        &nbsp;<i>({error.matchedOrder.join(",")})</i>
                                    </>
                                )}
                            </span>
                        </td>
                        <td>{error.rawProject ? error.rawProject : error.file}</td>
                        <td>
                            <small>{error.matcher}</small>
                        </td>
                        <td>
                            <span className="badge d-block bg-theme text-theme-900 rounded-0 pt-5px w-70px" style={{ "minHeight": "18px" }}>
                                {error.section}
                            </span>
                        </td>
                    </tr>
                );
            }

            items.push(
                <div className="list-group-item d-flex px-3" key={i}>
                    <div className="me-3 pt-1">
                        <i className="fas fa-exclamation-circle text-white text-opacity-50 fa-fw fa-lg"></i>
                    </div>
                    <div className="flex-fill">
                        <div className="fw-400">
                            <a href={`${this.props.store.settings.folders.linkBase}/${i}`}>{i}</a>
                        </div>
                        <hr className="my-3" />
                        <table className="table table-striped table-borderless mb-2px small text-nowrap">
                            <tbody>{subErrors}</tbody>
                        </table>
                    </div>
                </div>
            );
        }

        return (
            <>
                <ul className="breadcrumb">
                    <li className="breadcrumb-item">HOME</li>
                    <li className="breadcrumb-item">FOLDERS</li>
                    <li className="breadcrumb-item active">ERRORS</li>
                </ul>

                <h1 className="page-header">
                    Folder Errors <small>View the list and more</small>
                </h1>

                <div className="card">
                    <div className="card-body">
                        <div className="list-group list-group-flush">{items}</div>
                    </div>
                    <div className="card-arrow">
                        <div className="card-arrow-top-left"></div>
                        <div className="card-arrow-top-right"></div>
                        <div className="card-arrow-bottom-left"></div>
                        <div className="card-arrow-bottom-right"></div>
                    </div>
                </div>
            </>
        );
    }
}
