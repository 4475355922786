import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Alert } from "react-bootstrap";
import Store from "../libs/store";
import { timeout } from "../libs/utils/utils";

@observer
export default class Login extends React.Component<{ store: Store }> {
    @observable
    username: string = "";
    @observable
    password: string = "";
    @observable
    hasError = false;
    @observable
    errorMessage = "";

    constructor(props: any) {
        super(props);

        makeObservable(this);
    }

    @action
    onSignIn = async () => {
        try {
            await (
                await this.props.store.login()
            )({ username: this.username, password: this.password });
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;
            if (e.response && e.response.data) {
                console.log('here', e.response.data)
                this.errorMessage = e.response.data.errMsg
            } else {
                this.errorMessage = `We got unspecified error: ${e}`
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    render(): React.ReactNode {
        return (
            <div className="login">
                <div className="login-content">
                    <h1 className="text-center">Sign In</h1>
                    <div className="text-white text-opacity-50 text-center mb-4">For your protection, please verify your identity.</div>

                    {this.hasError && <Alert variant="danger">{this.errorMessage}</Alert>}

                    <div className="mb-3">
                        <label className="form-label">
                            Username <span className="text-danger">*</span>
                        </label>
                        <input type="text" className="form-control form-control-lg bg-white bg-opacity-5" value={this.username} onChange={action((e) => this.username = e.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">
                            Password <span className="text-danger">*</span>
                        </label>
                        <input type="password" className="form-control form-control-lg bg-white bg-opacity-5" value={this.password} onChange={action((e) => this.password = e.target.value)} />
                    </div>
                    <button type="submit" className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3" onClick={this.onSignIn}>
                        Sign In
                    </button>
                </div>
            </div>
        );
    }
}
