import { Material, TaxRate, Units } from "shared/models-web";
import Api from "../utils/api";


export default class CodetablesApi extends Api {
    private static PREFIX = "/api/v1/codetables";

    list_taxrate = async () => {
        return (await this.axios.get(`${CodetablesApi.PREFIX}/taxrates/list`)).data;
    }

    load_taxrate = async (id: string) => {
        return (await this.axios.get(`${CodetablesApi.PREFIX}/taxrates/load/${id}`)).data;
    }

    add_taxrate = async (data: TaxRate) => {
        return (await this.axios.post(`${CodetablesApi.PREFIX}/taxrates/save/new`, { data })).data;
    }

    update_taxrate = async (id: string, data: TaxRate) => {
        return (await this.axios.post(`${CodetablesApi.PREFIX}/taxrates/save/${id}`, { data })).data;
    }

    list_material = async () => {
        return (await this.axios.get(`${CodetablesApi.PREFIX}/materials/list`)).data;
    }

    load_material = async (id: string) => {
        return (await this.axios.get(`${CodetablesApi.PREFIX}/materials/load/${id}`)).data;
    }

    add_material = async (data: Material) => {
        return (await this.axios.post(`${CodetablesApi.PREFIX}/materials/save/new`, { data })).data;
    }

    update_material = async (id: string, data: Material) => {
        return (await this.axios.post(`${CodetablesApi.PREFIX}/materials/save/${id}`, { data })).data;
    }

    list_unit = async () => {
        return (await this.axios.get(`${CodetablesApi.PREFIX}/units/list`)).data;
    }

    load_unit = async (id: string) => {
        return (await this.axios.get(`${CodetablesApi.PREFIX}/units/load/${id}`)).data;
    }

    add_unit = async (data: Units) => {
        return (await this.axios.post(`${CodetablesApi.PREFIX}/units/save/new`, { data })).data;
    }

    update_unit = async (id: string, data: Units) => {
        return (await this.axios.post(`${CodetablesApi.PREFIX}/units/save/${id}`, { data })).data;
    }
}