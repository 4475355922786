export default function Dashboard(props: {}) {
    return (
        <>
            <ul className="breadcrumb">
                <li className="breadcrumb-item">
                    HOME
                </li>
                <li className="breadcrumb-item active">DASHBOARD</li>
            </ul>

            <h1 className="page-header">
                Dashboard <small>See the system status</small>
            </h1>

            {/* <p>Start build your page here</p> */}
        </>
    );
}
