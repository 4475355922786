import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Alert, Form } from "react-bootstrap";
import { User } from "shared/models-web";
import Popup from "../../components/ui/Popup";
import Table from "../../components/ui/Table";
import Store from "../../libs/store";
import { timeout } from "../../libs/utils/utils";
import UserEditPopup from "./UserEdit/Popup";

@observer
export default class Users extends React.Component<{ store: Store }> {
    @observable
    private data: User[] = [];
    @observable
    hasError = false;
    @observable
    errorMessage = "";

    @observable
    showAddUpdateForm = false;
    @observable
    popupHasError = false;
    @observable
    popupErrorMessage = "";

    @observable
    user: User = {
        firstName: "",
        lastName: "",
        username: "",
        permissions: {
            entries: {},
            isAdmin: false,
            isHidden: false,
        },
    };
    @observable
    userId?: string;
    @observable
    repeatedPassword: string = "";
    @observable
    newPassword: string = "";

    constructor(props: any) {
        super(props);

        this.init();
        makeObservable(this);
    }

    @action
    init = async () => {
        try {
            const data = await this.props.store.userApi.list();

            this.data = data.data.users;
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    @action
    load = async (userId: string) => {
        try {
            const data = await this.props.store.userApi.load(userId);

            this.user = data.data.user;
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.popupErrorMessage = e.response.data.errMsg;
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        }
    };

    @action
    onOpenAddEditUser = async (userId?: string) => {
        this.userId = userId;

        if (userId) {
            await this.load(userId);
        } else {
            this.user = {
                firstName: "",
                lastName: "",
                username: "",
                permissions: {
                    entries: {},
                    isAdmin: false,
                    isHidden: false,
                },
            };
        }

        this.showAddUpdateForm = true;
    };

    @action
    onCloseAddEditUser = () => {
        this.showAddUpdateForm = false;
    };

    @action
    onSaveAddEditUser = async () => {
        try {
            if (this.userId) {
                await this.props.store.userApi.update_user(this.userId, this.user, {
                    passwordNew: this.newPassword,
                    passwordRepeated: this.repeatedPassword,
                });
            } else {
                await this.props.store.userApi.add_user(this.user, {
                    passwordNew: this.newPassword,
                    passwordRepeated: this.repeatedPassword,
                });
            }

            this.showAddUpdateForm = false;
            await this.init();
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.popupErrorMessage = e.response.data.errMsg;
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        }
    };

    render(): React.ReactNode {
        return (
            <>
                <ul className="breadcrumb">
                    <li className="breadcrumb-item">HOME</li>
                    <li className="breadcrumb-item active">USERS</li>
                </ul>

                <h1 className="page-header">
                    Users <small>View the list and more</small>
                </h1>

                {this.hasError && <Alert variant="danger">{this.errorMessage}</Alert>}

                <div className="d-flex align-items-center mb-3">
                    <div className="btn-group">
                        <button type="button" className="btn btn-sm btn-outline-theme rounded-0" onClick={() => this.onOpenAddEditUser()}>
                            <i className="fa fa-fw fa-plus" />
                            &nbsp;
                            <span>Add</span>
                        </button>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <Table
                            columns={[
                                {
                                    Header: "First name",
                                    accessor: "firstName",
                                    disableSortBy: false,
                                },
                                {
                                    Header: "Last Name",
                                    accessor: "lastName",
                                    disableSortBy: false,
                                },
                                {
                                    Header: "Username",
                                    accessor: "username",
                                    disableSortBy: false,
                                },
                                {
                                    Header: "Email",
                                    accessor: "emial",
                                    disableSortBy: false,
                                },
                                {
                                    Header: "Actions",
                                    accessor: "action",
                                    disableSortBy: true,
                                    actions: (row: any) => {
                                        return (
                                            <div className="btn-group btn-group-sm">
                                                <button type="button" className="btn btn-outline-theme btn-sm" onClick={() => this.onOpenAddEditUser(row.original._id)}>
                                                    Edit
                                                </button>
                                                <button type="button" className="btn btn-outline-theme btn-sm">
                                                    Delete
                                                </button>
                                            </div>
                                        );
                                    },
                                },
                            ]}
                            data={this.data}
                        />
                    </div>
                    <div className="card-arrow">
                        <div className="card-arrow-top-left"></div>
                        <div className="card-arrow-top-right"></div>
                        <div className="card-arrow-bottom-left"></div>
                        <div className="card-arrow-bottom-right"></div>
                    </div>
                </div>

                <Popup title="Enter details " show={this.showAddUpdateForm} onClose={this.onCloseAddEditUser} onSave={this.onSaveAddEditUser}>
                    <UserEditPopup
                        store={this.props.store}
                        popupHasError={this.popupHasError}
                        popupErrorMessage={this.popupErrorMessage}
                        userSource={this.user}
                        onChange={action((user, password) => {
                            this.user = user;
                            this.newPassword = password.newPassword;
                            this.repeatedPassword = password.repeatedPassword;
                        })}
                    />
                </Popup>
            </>
        );
    }
}
