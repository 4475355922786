import { action, makeObservable, observable, toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Alert } from "react-bootstrap";
import { Company, TaxRate } from "shared/models-web";
import Popup from "../../components/ui/Popup";
import Table from "../../components/ui/Table";
import Store from "../../libs/store";
import { timeout } from "../../libs/utils/utils";
import CompanyEdit from "./compaines/CompanyEdit";
import _ from 'lodash';

@observer
export default class Compaines extends React.Component<{ store: Store }> {
    @observable
    private data: Company[] = [];

    @observable
    hasError = false;
    @observable
    errorMessage = "";

    @observable
    popupHasError = false;
    @observable
    popupErrorMessage = "";

    @observable
    editTitle = "";
    @observable
    editShow = false;
    @observable
    editCompany?: Company;

    @observable
    taxRates: TaxRate[] = [];

    informer?: (company: Company) => void;

    constructor(props: any) {
        super(props);

        this.init();
        makeObservable(this);
    }

    @action
    init = async () => {
        try {
            this.data = (await this.props.store.companiesApi.list()).data.companies;
            this.taxRates = (await this.props.store.codetablesApi.list_taxrate()).data.taxRates;
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    @action
    editOnOpenPopup = async (company?: Company) => {
        this.editShow = true;
        this.editCompany = company;

        if (!this.editCompany) {
            this.editTitle = "Add new company";
        } else {
            this.editTitle = "Edit compnay";
        }
    };

    @action
    editOnClosePopup = async () => {
        this.editShow = false;
    };

    @action
    editOnSave = async () => {
        try {
            if (!this.editCompany) {
                throw new Error("Invalid company object");
            }

            if (!this.editCompany.paymentDueDate || this.editCompany.paymentDueDate.type === "-1" || !this.editCompany.paymentDueDate.type || this.editCompany.paymentDueDate.type === "" || this.editCompany.paymentDueDate.value === "") {
                throw new Error("Please select value payment due date type");
            }

            if (!this.editCompany._id) {
                await this.props.store.companiesApi.add(this.editCompany);
            } else {
                await this.props.store.companiesApi.update(this.editCompany._id, this.editCompany);
            }

            this.editShow = false;

            await this.init();
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.popupErrorMessage = e.response.data.errMsg;
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        }
    };

    @action
    loadFromInternet = async (vatId?: string) => {
        try {
            if (!vatId) {
                throw new Error("Vat can't be empty for search");
            }

            const company = (await this.props.store.companiesApi.search(vatId)).data.company;

            this.informer && this.informer(_.omit(company, ["contacts", "discount", "paymentDueDate"]) as any);
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.popupErrorMessage = e.response.data.errMsg;
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    @action
    deleteCompany = async (id: string) => {
        try {
            await this.props.store.companiesApi.remove(id);

            await this.init();
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    render(): React.ReactNode {
        return (
            <>
                <ul className="breadcrumb">
                    <li className="breadcrumb-item">HOME</li>
                    <li className="breadcrumb-item">CODETABLES</li>
                    <li className="breadcrumb-item active">COMPANIES</li>
                </ul>

                <h1 className="page-header">
                    Companies <small>View the list and more</small>
                </h1>

                {this.hasError && <Alert variant="danger">{this.errorMessage}</Alert>}

                <div className="d-flex align-items-center mb-3">
                    <div className="btn-group">
                        <button type="button" className="btn btn-sm btn-outline-theme rounded-0" onClick={() => this.editOnOpenPopup()}>
                            <i className="fa fa-fw fa-plus" />
                            &nbsp;
                            <span>Add</span>
                        </button>
                    </div>
                    <span className="ms-3">Select best option for you</span>
                </div>

                <div className="card">
                    <div className="card-body">
                        <Table
                            columns={[
                                {
                                    Header: "VAT ID",
                                    accessor: "vatId",
                                    disableSortBy: false,
                                },
                                {
                                    Header: "Name",
                                    accessor: "name",
                                    disableSortBy: false,
                                },
                                {
                                    Header: "Address",
                                    accessor: "address.address",
                                    disableSortBy: false,
                                },
                                {
                                    Header: "City",
                                    accessor: "address.city",
                                    disableSortBy: false,
                                },
                                {
                                    Header: "Country",
                                    accessor: "address.country",
                                    disableSortBy: false,
                                },
                                {
                                    Header: "Actions",
                                    accessor: "action",
                                    disableSortBy: true,
                                    actions: (row: any) => {
                                        return (
                                            <div className="btn-group btn-group-sm">
                                                <button type="button" className="btn btn-outline-theme btn-sm" onClick={() => this.editOnOpenPopup(row.original)}>
                                                    Edit
                                                </button>
                                                <button type="button" className="btn btn-outline-theme btn-sm" onClick={() => this.deleteCompany(row.original._id)}>
                                                    Delete
                                                </button>
                                            </div>
                                        );
                                    },
                                },
                            ]}
                            data={this.data}
                        />
                    </div>
                    <div className="card-arrow">
                        <div className="card-arrow-top-left"></div>
                        <div className="card-arrow-top-right"></div>
                        <div className="card-arrow-bottom-left"></div>
                        <div className="card-arrow-bottom-right"></div>
                    </div>
                </div>

                <Popup title={this.editTitle} show={this.editShow} onClose={this.editOnClosePopup} onSave={this.editOnSave} size="xl">
                    <CompanyEdit store={this.props.store} popupHasError={this.popupHasError} popupErrorMessage={this.popupErrorMessage} taxRates={this.taxRates} company={this.editCompany} loadFromInternet={this.loadFromInternet} onChange={action((company) => (this.editCompany = company))} getInformer={(informer) => (this.informer = informer)} />
                </Popup>
            </>
        );
    }
}
