import { EventEmitter } from "events";
import { observable, action, makeObservable, toJS } from 'mobx'
import settings from '../settings.json';
import consola from 'consola';
import { io, Socket } from "socket.io-client";
import { get_auth_provider, get_session_provider } from "./auth";
import UsersApi from "./api/users";
import AuthApi from "./api/auth";
import DocumentsApi from "./api/documents";
import CompainesApi from "./api/compaines";
import CodetablesApi from "./api/codetables";
import FoldersApi from "./api/folders";
import { Permissions } from 'shared/models-web'
import _ from 'lodash';
import EditorApi from "./api/editor";
import NotificationApi from "./api/notifications";

export default class Store extends EventEmitter {
    public settings = settings;
    public userApi: UsersApi;
    public authApi: AuthApi;
    public documentsApi: DocumentsApi;
    public companiesApi: CompainesApi;
    public codetablesApi: CodetablesApi;
    public folderApi: FoldersApi;
    public editorApi: EditorApi;
    public notificationsApi: NotificationApi;

    private logger = consola.withTag("store");
    private session;
    private auth;
    private socket: Socket;

    @observable
    public loginState: {
        is_loggined: boolean,
        permissions?: Permissions
    } = {
            is_loggined: false,
        };

    constructor() {
        super();

        this.session = get_session_provider(this, settings.auth.session);
        this.auth = get_auth_provider(this, settings.auth.type);

        this.userApi = new UsersApi("");
        this.authApi = new AuthApi("");
        this.documentsApi = new DocumentsApi("");
        this.companiesApi = new CompainesApi("");
        this.codetablesApi = new CodetablesApi("");
        this.folderApi = new FoldersApi("");
        this.editorApi = new EditorApi("");
        this.notificationsApi = new NotificationApi("");

        this.socket = io("");

        this.session.on('session.updated', action(async (token?: string) => {
            this.logger.trace(token);

            if (token) {
                this.update_apis(token);
                await this.load_users_permissions();

                this.loginState.is_loggined = true;
            } else {
                this.loginState.is_loggined = false;
            }

            this.logger.info(`User is logged in: ${this.loginState.is_loggined}`);
        }));

        this.socket.on("folders.error", action((data: any) => {
            this.emit("folders.error", data);

            this.emit("disable.button", {
                action: "Scan folder",
                disabled: data.isScanning
            });
        }));


        this.socket.on("folders.scan", action((data: any) => {

            this.emit("disable.button", {
                action: "Scan folder",
                disabled: data.isScanning
            });

            if (!data.isScanning) {
                this.emit("reload.documents")
            }
        }));

        this.socket.on('notifications.event', (data: any) => {
            this.emit('notifications.event', data)
        })

        makeObservable(this);
    }

    private update_apis = (token: string) => {
        this.userApi.update_config({
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        this.documentsApi.update_config({
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        this.companiesApi.update_config({
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        this.codetablesApi.update_config({
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        this.folderApi.update_config({
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        this.editorApi.update_config({
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        this.notificationsApi.update_config({
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    @action
    init = async () => {
        this.logger.info(`store init`);

        await this.session.init_session();
        await this.load_users_permissions();
    }

    get_sessions = () => this.session;

    /**
     * Used to access auth provider functions
     * 
     * @returns (info: T) => Promise<boolean>
     */
    login = async () => {
        return this.auth.login();
    }

    logout = async () => {
        await this.session.update_session();
    }

    get_auth_type = () => settings.auth.type;

    @action
    load_users_permissions = async () => {
        if (!this.session.user_id) {
            return;
        }

        const permissions = (await this.userApi.list_premission(this.session.user_id)).data;

        this.loginState.permissions = permissions.permissions;
    }

    check_permissions = (permission: string, check: { read?: boolean, write?: boolean, delete?: boolean }): boolean => {
        if (!this.loginState.permissions) {
            throw new Error("Permissions not ready");
        }

        if (this.loginState.permissions.isAdmin) {
            return true;
        }

        if (!this.loginState.permissions.entries) {
            this.loginState.permissions.entries = {};
        }

        const entry = this.loginState.permissions.entries[permission];

        if (!entry) {
            return false;
        }

        if (check.delete) {
            return entry.canDelete;
        }

        if (check.write) {
            return entry.canWrite;
        }

        if (check.read) {
            return entry.canRead;
        }

        return false;
    }
}