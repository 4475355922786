import Api from "../utils/api";
import { DocumentFileRequest, DocumentSendEmailRequest, LoadDocumentRequest, TypedDocument } from 'shared/models-web';

export default class DocumentsApi extends Api {
    private static PREFIX = "/api/v1/documents";

    list = async (type: string) => {
        return (await this.axios.get(`${DocumentsApi.PREFIX}/list/${type}`)).data;
    }

    listFilterted = async (params: LoadDocumentRequest) => {
        return (await this.axios.post(`${DocumentsApi.PREFIX}/list/${params.type}`, params)).data;
    }

    load = async (id: string) => {
        return (await this.axios.get(`${DocumentsApi.PREFIX}/${id}/load`)).data;
    }

    add = async (document: TypedDocument) => {
        return (await this.axios.post(`${DocumentsApi.PREFIX}/new/save`, { document })).data;
    }

    update = async (id: string, document: TypedDocument) => {
        return (await this.axios.post(`${DocumentsApi.PREFIX}/${id}/save`, { document })).data;
    }

    status = async (id: string, status: string) => {
        return (await this.axios.post(`${DocumentsApi.PREFIX}/${id}/status`, { status })).data;
    }

    status_bulk = async (document: LoadDocumentRequest, status: string) => {
        return (await this.axios.post(`${DocumentsApi.PREFIX}/status/${document.type}/bulk`, { status, document })).data;
    }

    pdf = async (id: string) => {
        return (await this.axios.post(`${DocumentsApi.PREFIX}/${id}/pdf`, {}, { responseType: 'arraybuffer' }));
    }

    send = async (id: string, email: string, message?: string) => {
        return (await this.axios.post(`${DocumentsApi.PREFIX}/${id}/email`, { email, message })).data;
    }

    send_bulk = async (document: LoadDocumentRequest) => {
        return (await this.axios.post(`${DocumentsApi.PREFIX}/email/${document.type}/bulk`, { document })).data;
    }

    send_multiple = async (params: DocumentSendEmailRequest) => {
        return (await this.axios.post(`${DocumentsApi.PREFIX}/email/${params.loadDocument.type}`, params)).data;
    }

    vod_xml = async (params: LoadDocumentRequest) => {
        return (await this.axios.post(`${DocumentsApi.PREFIX}/xml/${params.type}`, params, { responseType: 'arraybuffer' }));
    }

    csv = async (params: LoadDocumentRequest) => {
        return (await this.axios.post(`${DocumentsApi.PREFIX}/csv/${params.type}`, params, { responseType: 'arraybuffer' }));
    }

    pdfs = async (params: DocumentFileRequest) => {
        return (await this.axios.get(`${DocumentsApi.PREFIX}/pdfs/${params.fileId}`, { responseType: 'arraybuffer' }));
    }

    pdfs_create = async (params: LoadDocumentRequest) => {
        return (await this.axios.post(`${DocumentsApi.PREFIX}/pdfs`, params));
    }

    pdfs_list = async () => {
        return (await this.axios.post(`${DocumentsApi.PREFIX}/pdfs/list`, {}));
    }

    pdfs_remove = async (params: DocumentFileRequest) => {
        return (await this.axios.post(`${DocumentsApi.PREFIX}/pdfs/remove`, params));
    }

    move = async (id: string, type: string) => {
        return (await this.axios.post(`${DocumentsApi.PREFIX}/${id}/move`, { type })).data;
    }

    move_bulk = async (documentIds: string[], type: string) => {
        return (await this.axios.post(`${DocumentsApi.PREFIX}/move`, { type, documentIds })).data;
    }
}