import _ from "lodash";
import { action, makeObservable, observable, toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Alert } from "react-bootstrap";
import { ModuleConfiguration } from "shared/models-web";
import Store from "../../libs/store";
import { timeout } from "../../libs/utils/utils";

@observer
export default class Configuration extends React.Component<{ store: Store }> {
    @observable
    hasError = false;
    @observable
    errorMessage = "";

    @observable
    configuration?: ModuleConfiguration;

    constructor(props: any) {
        super(props);

        makeObservable(this);

        this.load();
    }

    @action
    load = async () => {
        try {
            this.configuration = (await this.props.store.editorApi.options()).data.configuration;

            console.log(toJS(this.configuration));
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    render(): React.ReactNode {
        return (
            <>
                <ul className="breadcrumb">
                    <li className="breadcrumb-item">HOME</li>
                    <li className="breadcrumb-item">SETTINGS</li>
                    <li className="breadcrumb-item active">CONFIGURATION</li>
                </ul>

                <h1 className="page-header">
                    Configuration <small>all settings in one place</small>
                </h1>

                {this.hasError && <Alert variant="danger">{this.errorMessage}</Alert>}

                <div className="mb-5">
                    <h4>
                        <i className={"fa-fw text-theme far fa-copy"}></i> Documents
                    </h4>
                    <p></p>
                    <div className="card">
                        <div className="list-group list-group-flush">
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>Types</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> Current types: {this.configuration && this.configuration.documents && this.configuration.documents.types.length}
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>Filename format</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> {this.configuration && this.configuration.documents && this.configuration.documents.filenameFormat}
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>Default status</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> {this.configuration && this.configuration.documents && this.configuration.documents.statusDefault}
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>Status order</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> {this.configuration && this.configuration.documents && this.configuration.documents.statusesOrder.join(", ")}
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>Statuses</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> {this.configuration && this.configuration.documents && _.map(this.configuration.documents.statuses, (item, key) => key).join(", ")}
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>CSV</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> CSV format configuration
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>XML</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> XML mapping info
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>Status actions</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> Data transformations
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>Emailing</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> Attechments, copies
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-arrow">
                            <div className="card-arrow-top-left"></div>
                            <div className="card-arrow-top-right"></div>
                            <div className="card-arrow-bottom-left"></div>
                            <div className="card-arrow-bottom-right"></div>
                        </div>
                    </div>
                </div>

                <div className="mb-5">
                    <h4>
                        <i className={"fa-fw text-theme far fa-envelope"}></i> Email
                    </h4>
                    <p></p>
                    <div className="card">
                        <div className="list-group list-group-flush">
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>Server</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> {this.configuration && this.configuration.email && this.configuration.email.server}
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>Port</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> {this.configuration && this.configuration.email && this.configuration.email.port}
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>Username</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> {this.configuration && this.configuration.email && this.configuration.email.username}
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>Password</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> ********
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>Encryption</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> {this.configuration && this.configuration.email && this.configuration.email.encryption}
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>Display name</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> {this.configuration && this.configuration.email && this.configuration.email.display_name}
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>Email address</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> {this.configuration && this.configuration.email && this.configuration.email.email_address}
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>Replay to address</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> {this.configuration && this.configuration.email && this.configuration.email.replay_to_address}
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-arrow">
                            <div className="card-arrow-top-left"></div>
                            <div className="card-arrow-top-right"></div>
                            <div className="card-arrow-bottom-left"></div>
                            <div className="card-arrow-bottom-right"></div>
                        </div>
                    </div>
                </div>

                <div className="mb-5">
                    <h4>
                        <i className={"fa-fw text-theme far fa-folder"}></i> Folder
                    </h4>
                    <p></p>
                    <div className="card">
                        <div className="list-group list-group-flush">
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>Enabled</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> State: {this.configuration && this.configuration.folder && (this.configuration.folder.enabled ? "yes" : "no")}
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>Enabled periodic scan</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> State: {this.configuration && this.configuration.folder && (this.configuration.folder.enabledPeriodicScan ? "yes" : "no")}
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>Document type</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> {this.configuration && this.configuration.folder && this.configuration.folder.document && this.configuration.folder.document.documentType}
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>Ident validation</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> Constriants: {this.configuration && this.configuration.folder && this.configuration.folder.identValidation && this.configuration.folder.identValidation.constrains.length}
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-arrow">
                            <div className="card-arrow-top-left"></div>
                            <div className="card-arrow-top-right"></div>
                            <div className="card-arrow-bottom-left"></div>
                            <div className="card-arrow-bottom-right"></div>
                        </div>
                    </div>
                </div>

                <div className="mb-5">
                    <h4>
                        <i className={"fa-fw text-theme fas fa-university"}></i> Companies
                    </h4>
                    <p></p>
                    <div className="card">
                        <div className="list-group list-group-flush">
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>Default tax zone</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> Zone: {this.configuration && this.configuration.companies && this.configuration.companies.defaultZone.zoneName}
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>Other zones</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> Other zones: {this.configuration && this.configuration.companies && _.map(this.configuration.companies.otherZones, (item, key) => key).join(", ")}
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                            <div className="list-group-item d-flex align-items-center">
                                <div className="flex-1 text-break">
                                    <div>Default payment due dates</div>
                                    <div className="text-white text-opacity-50 d-flex align-items-center">
                                        <i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> Type: `{this.configuration && this.configuration.companies && this.configuration.companies.defaultPaymentDueDate.type}` value:  {this.configuration && this.configuration.companies && this.configuration.companies.defaultPaymentDueDate.value}
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-outline-default w-100px" disabled={true}>
                                        Edit
                                    </button>
                                </div>
                            </div>                            
                        </div>
                        <div className="card-arrow">
                            <div className="card-arrow-top-left"></div>
                            <div className="card-arrow-top-right"></div>
                            <div className="card-arrow-bottom-left"></div>
                            <div className="card-arrow-bottom-right"></div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
