import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Alert, Form } from "react-bootstrap";
import { Material, Units } from "shared/models-web";
import Popup from "../../components/ui/Popup";
import Table from "../../components/ui/Table";
import Store from "../../libs/store";
import { timeout } from "../../libs/utils/utils";
import _ from "lodash";
import OptionsEdit from "./material/OptionsEdit";

@observer
export default class Materials extends React.Component<{ store: Store }> {
    @observable
    private data: Material[] = [];
    @observable
    hasError = false;
    @observable
    errorMessage = "";

    @observable
    showAddUpdateForm = false;
    popupHasError = false;
    @observable
    popupErrorMessage = "";

    @observable
    material: Material = {
        name: "",
        price: 0,
        options: {}
    };
    @observable
    materialId?: string;
    @observable
    units: Units[] = [];

    constructor(props: any) {
        super(props);

        this.init();
        makeObservable(this);
    }

    @action
    init = async () => {
        try {
            const data = await this.props.store.codetablesApi.list_material();

            this.data = data.data.materials;

            const data1 = await this.props.store.codetablesApi.list_unit();

            this.units = data1.data.units;
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    @action
    load = async (materialId: string) => {
        try {
            const data = await this.props.store.codetablesApi.load_material(materialId);

            this.material = data.data.material;

            if(!this.material.options) {
                //init optins if missing ...
                this.material.options = {};
            }
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.popupErrorMessage = e.response.data.errMsg;
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        }
    };

    @action
    onOpenAddEditMaterial = async (materialId?: string) => {
        this.materialId = materialId;

        if (materialId) {
            await this.load(materialId);
        } else {
            this.material = {
                name: "",
                price: 0,
                options: {}
            };
        }

        this.showAddUpdateForm = true;
    };

    @action
    onCloseAddEditMaterial = () => {
        this.showAddUpdateForm = false;
    };

    @action
    onSaveAddEditMaterial = async () => {
        try {
            if (this.materialId) {
                await this.props.store.codetablesApi.update_material(this.materialId, this.material);
            } else {
                await this.props.store.codetablesApi.add_material(this.material);
            }

            this.showAddUpdateForm = false;
            await this.init();
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.popupErrorMessage = e.response.data.errMsg;
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        }
    };

    render(): React.ReactNode {
        return (
            <>
                <ul className="breadcrumb">
                    <li className="breadcrumb-item">HOME</li>
                    <li className="breadcrumb-item">CODETABLES</li>
                    <li className="breadcrumb-item active">MATERIALS</li>
                </ul>

                <h1 className="page-header">
                    Materials <small>View the list and more</small>
                </h1>

                {this.hasError && <Alert variant="danger">{this.errorMessage}</Alert>}

                <div className="d-flex align-items-center mb-3">
                    <div className="btn-group">
                        <button type="button" className="btn btn-sm btn-outline-theme rounded-0" onClick={() => this.onOpenAddEditMaterial()}>
                            <i className="fa fa-fw fa-plus" />
                            &nbsp;
                            <span>Add</span>
                        </button>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <Table
                            columns={[
                                {
                                    Header: "Name",
                                    accessor: "name",
                                    disableSortBy: false,
                                },
                                {
                                    Header: "Text",
                                    accessor: "text",
                                    disableSortBy: false,
                                },
                                {
                                    Header: "Price",
                                    accessor: "price",
                                    disableSortBy: false,
                                },
                                {
                                    Header: "Unit",
                                    accessor: "unitId",
                                    disableSortBy: false,
                                    actions: (row: any) => {
                                        const unit = _.find(this.units, { _id: row.original.unitId });
                                        return unit ? unit.symbol : "";
                                    },
                                },
                                {
                                    Header: "Actions",
                                    accessor: "action",
                                    disableSortBy: true,
                                    actions: (row: any) => {
                                        return (
                                            <div className="btn-group btn-group-sm" onClick={() => this.onOpenAddEditMaterial(row.original._id)}>
                                                <button type="button" className="btn btn-outline-theme btn-sm">
                                                    Edit
                                                </button>
                                                <button type="button" className="btn btn-outline-theme btn-sm">
                                                    Delete
                                                </button>
                                            </div>
                                        );
                                    },
                                },
                            ]}
                            data={this.data}
                        />
                    </div>
                    <div className="card-arrow">
                        <div className="card-arrow-top-left"></div>
                        <div className="card-arrow-top-right"></div>
                        <div className="card-arrow-bottom-left"></div>
                        <div className="card-arrow-bottom-right"></div>
                    </div>
                </div>

                <Popup title="Enter details " show={this.showAddUpdateForm} onClose={this.onCloseAddEditMaterial} onSave={this.onSaveAddEditMaterial}>
                    {this.popupHasError && <Alert variant="danger">{this.popupErrorMessage}</Alert>}

                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Name" value={this.material && this.material.name} onChange={action((e) => this.material && (this.material.name = e.target.value))} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="text">
                        <Form.Label>Text</Form.Label>
                        <Form.Control type="text" placeholder="Text" value={this.material && this.material.text} onChange={action((e) => this.material && (this.material.text = e.target.value))} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="price">
                        <Form.Label>Price</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Price"
                            value={this.material && this.material.price}
                            onChange={action((e) => {
                                const price = Number.parseFloat(e.target.value);

                                if (this.material && !isNaN(price)) {
                                    this.material.price = price;
                                }
                            })}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="unit">
                        <Form.Label>Unit</Form.Label>
                        <Form.Select placeholder="Select unit" value={this.material && this.material.unitId} onChange={action((e) => this.material && (this.material.unitId = e.target.value))}>
                            <option>Select ...</option>

                            {_.map(this.units, (entry, index) => {
                                return (
                                    <option key={entry._id} value={entry._id}>
                                        {entry.name}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>

                    <OptionsEdit collectData={(options) => this.material.options = options} options={this.material.options} />
                </Popup>
            </>
        );
    }
}
