import React from "react";
import { observer } from "mobx-react";
import Store from "../../libs/store";
import { Dropdown } from "react-bootstrap";
import { ToggleOff } from "react-bootstrap-icons";
import Notifications from "./Notifications";
import { action, makeObservable, observable } from "mobx";

@observer
export default class Header extends React.Component<{ store: Store }> {
    @observable
    disbaledButtons: { [id: string]: boolean } = {};

    constructor(props: any) {
        super(props);

        for (const action of this.props.store.settings.header.actions) {
            this.disbaledButtons[(action as any).text] = false;
        }

        makeObservable(this);
    }

    componentDidMount = () => {
        this.props.store.on("disable.button", this.onData);
    };

    componentWillUnmount = () => {
        this.props.store.off("disable.button", this.onData);
    };

    @action
    onData = async (data: { action: string; disabled: boolean }) => {
        this.disbaledButtons[data.action] = data.disabled;
    };

    @action
    onActionClick = async (action: string) => {
        try {
            switch (action) {
                case "trigger_rescan":
                    await this.props.store.folderApi.trigger_rescan();

                    this.props.store.emit(action);
                    break;
            }
        } catch (rawE) {
            //Some idot at typescript made exception of type unknwon ...
            const e = rawE as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.get_sessions().is_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
            }
        }
    };

    render(): React.ReactNode {
        const actions = [];
        for (const actionRaw of this.props.store.settings.header.actions) {
            const action = (actionRaw as any);
            actions.push(
                <button className="btn btn-outline-yellow" onClick={() => this.onActionClick(action.do)} key={action.text} disabled={this.disbaledButtons[action.text]}>
                    {action.text}
                </button>
            );
        }

        return (
            <div id="header" className="app-header">
                <div className="desktop-toggler">
                    <button type="button" className="menu-toggler" data-toggle-class="app-sidebar-collapsed" data-dismiss-class="app-sidebar-toggled" data-toggle-target=".app">
                        <span className="bar"></span>
                        <span className="bar"></span>
                        <span className="bar"></span>
                    </button>
                </div>

                <div className="mobile-toggler">
                    <button type="button" className="menu-toggler" data-toggle-class="app-sidebar-mobile-toggled" data-toggle-target=".app">
                        <span className="bar"></span>
                        <span className="bar"></span>
                        <span className="bar"></span>
                    </button>
                </div>

                <div className="brand">
                    <span className="pointer brand-logo">
                        <span className="brand-img">
                            <span className="brand-img-text text-theme">H</span>
                        </span>
                        <span className="brand-text">{this.props.store.settings.header.title}</span>
                    </span>
                </div>

                <div className="menu">
                    {actions}

                    <Notifications store={this.props.store} />

                    <Dropdown className="menu-item dropdown-mobile-full" align="end">
                        <Dropdown.Toggle as="a" className="menu-link">
                            Account
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-end me-lg-3 fs-11px mt-1">
                            <Dropdown.Item onClick={() => console.log("aaa")} className="d-flex align-items-center">
                                LOGOUT{" "}
                                <i className="bi ms-auto text-theme fs-16px my-n1">
                                    <ToggleOff />
                                </i>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        );
    }
}
