import { LoadDocumentRequest } from "shared/models-web";
import Store from "../store";
import * as promotorExtras from './PromotorExtras';




export async function executeSelectionAction(store: Store, selectionAction: string, request: LoadDocumentRequest) {
    const context = (store.settings.documents.lists.selectionActions as any)[selectionAction];

    if (!context) {
        throw new Error(`Given selection action is missing (${selectionAction})`);
    }

    switch(context.use) {
        case 'PromotorExtras': 
            return promotorExtras.executeSelectionAction(store, context, request);
    }

}

export async function executeAdditionalActions(store: Store, selectionAction: string, request: LoadDocumentRequest) {
    const context = (store.settings.documents.lists.additionalActions as any)[selectionAction];

    if (!context) {
        return;
    }

    switch(context.use) {
        case 'PromotorExtras': 
            return promotorExtras.executeSelectionAction(store, context, request);
    }

}

export function getSelectionActions(store: Store) {
    return store.settings.documents.lists.selectionActions;
}