import { Company } from "shared/models-web";
import Api from "../utils/api";


export default class CompainesApi extends Api {
    private static PREFIX = "/api/v1/compaines";

    list = async () => {
        return (await this.axios.get(`${CompainesApi.PREFIX}/list`)).data;
    }

    load = async (id: string) => {
        return (await this.axios.get(`${CompainesApi.PREFIX}/load/${id}`)).data;
    }

    add = async (company: Company) => {
        return (await this.axios.post(`${CompainesApi.PREFIX}/save/new`, { company })).data;
    }

    update = async (id: string, company: Company) => {
        return (await this.axios.post(`${CompainesApi.PREFIX}/save/${id}`, { company })).data;
    }

    remove = async (id: string) => {
        return (await this.axios.delete(`${CompainesApi.PREFIX}/remove/${id}`)).data;
    }

    search = async (vat_id: string) => {
        return (await this.axios.post(`${CompainesApi.PREFIX}/search/internet`, { vat_id })).data;
    }
}