import { action, makeObservable, observable, observe, toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Alert, Form } from "react-bootstrap";
import { Company, Material, TaxRate, TypedDocument, Units } from "shared/models-web";
import Store from "../../libs/store";
import _ from "lodash";
import moment from "moment";
import { Typeahead } from "react-bootstrap-typeahead";
import Idents from "./DocumentEdit/Idents";
import { createTypedDocument } from "../../libs/utils/typed_document";

@observer
export default class DocumentEdit extends React.Component<{ store: Store; document?: TypedDocument; popupHasError: boolean; popupErrorMessage?: string; type?: string; compaines: Company[]; units: Units[]; materials: Material[]; taxRates: TaxRate[]; onChange: (document: TypedDocument) => void; getInformer: (informer: (document: TypedDocument) => void) => void }> {
    disposer1?: any;
    @observable
    company?: Company;
    @observable
    document: TypedDocument;

    constructor(props: any) {
        super(props);

        if (!this.props.type) {
            throw new Error("Can't load without type!");
        }

        makeObservable(this);

        if (this.props.document) {
            this.document = this.props.document;
        } else {
            this.document = createTypedDocument(this.props.type, this.props.store.settings.documents);
        }

        const company = _.find(this.props.compaines, { _id: this.document.companyId });

        if (company) {
            this.company = company;
        }

        this.attachObserver();
        this.props.getInformer(this.informer);
    }

    @action
    informer = (document: TypedDocument) => {
        this.document = Object.assign({}, this.document, document);
        this.attachObserver();
    };

    attachObserver = () => {
        this.disposer1 && this.disposer1();

        this.disposer1 = observe(this.document, () => {
            this.props.onChange(this.document);
        });
    };

    componentWillUnmount = () => {
        this.disposer1 && this.disposer1();
    };

    @action
    selectCompany = (input: any[]) => {
        if (!Array.isArray(input)) {
            throw new Error("Illigal argument");
        }

        if (input.length === 0 || input.length > 1) {
            this.document.companyId = undefined;
            this.company = undefined;
            return;
        }

        const company = _.find(this.props.compaines, { _id: input[0].id });

        if (!company) {
            this.document.companyId = undefined;
            this.company = undefined;
            return;
        }

        this.company = company;
        this.document.companyId = company._id;
    };

    render(): React.ReactNode {
        const yearSelectItems = [];
        yearSelectItems.push(<option value={-1}>Select ...</option>);
        const todaysYear = Number.parseInt(moment().format("YYYY"));
        for (let i = this.props.store.settings.documents.yearRange.from; i <= todaysYear; i++) {
            yearSelectItems.push(<option value={i}>{i}</option>);
        }

        const monthSelectItems = [];
        monthSelectItems.push(<option value={-1}>Select</option>);
        for (let i = 1; i <= 12; i++) {
            const month = moment(i > 9 ? i : `0${i}`, "MM").format("MMMM");
            monthSelectItems.push(<option value={month.toLowerCase()}>{month}</option>);
        }

        const compainesData = [];
        const selected = [];
        for (const company of this.props.compaines) {
            const option = {
                id: company._id,
                label: `${company.name}, ${company.vatId}`,
            };

            compainesData.push(option);

            if (this.document.companyId === company._id) {
                selected.push(option);
            }
        }

        return (
            <>
                <Alert show={this.props.popupHasError} variant="danger">
                    {this.props.popupErrorMessage}
                </Alert>

                <Form.Group className="mb-3" controlId="calculationRef">
                    <div className="row mb-3">
                        <div className="col">
                            <Form.Label>Year</Form.Label>
                            <Form.Select placeholder="Change status" value={this.document.year} onChange={action((e) => (this.document.year = Number.parseInt(e.target.value)))}>
                                {yearSelectItems}
                            </Form.Select>
                        </div>
                        <div className="col">
                            <Form.Label>Month</Form.Label>
                            <Form.Select placeholder="Change status" value={this.document.month} onChange={action((e) => (this.document.month = e.target.value))}>
                                {monthSelectItems}
                            </Form.Select>
                        </div>
                        <div className="col">
                            <Form.Label>Status</Form.Label>
                            <Form.Select placeholder="Change status" value={this.document.status} onChange={action((e) => (this.document.status = e.target.value))}>
                                <option>Select ...</option>

                                {_.map(this.props.store.settings.documents.statusesOrder, (entry, index) => {
                                    return (
                                        <option key={entry} value={entry}>
                                            {entry}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">
                            <Form.Label>Company</Form.Label>
                            <Typeahead options={compainesData} onChange={this.selectCompany} selected={selected} id="1" />
                        </div>
                        <div className="col">
                            <Form.Label>Contact</Form.Label>
                            <Form.Select placeholder="Content" value={this.document.contactId} onChange={action((e) => (this.document.contactId = e.target.value))}>
                                <option value="-1">Select ...</option>

                                {_.map((this.company && this.company.contacts) || [], (entry, index) => {
                                    let text = [];

                                    if (entry.department && entry.department.length > 0) {
                                        text.push(entry.department);
                                    }

                                    if (entry.name && entry.name.length > 0) {
                                        text.push(entry.name);
                                    }

                                    if (entry.email && entry.email.length > 0) {
                                        text = text.concat(entry.email);
                                    }

                                    return (
                                        <option key={entry._id} value={entry._id}>
                                            {text.join(", ")}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </div>
                        <div className="col">
                            <Form.Label>PO#</Form.Label>
                            <Form.Control type="text" value={this.document.poNumber} onChange={action((e) => (this.document.poNumber = e.target.value))} />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <Form.Label>Select service date</Form.Label>
                            <Form.Control
                                type="date"
                                value={moment(this.document.serviceDate, "x").isValid() ? moment(this.document.serviceDate, "x").format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
                                onChange={action((e) => {
                                    const data = moment(e.target.value, "YYYY-MM-DD");

                                    if (data.isValid()) {
                                        this.document.serviceDate = data.format("x");
                                    }
                                })}
                            />
                        </div>
                        <div className="col">
                            <Form.Label>Select due date</Form.Label>
                            <Form.Control
                                type="date"
                                value={moment(this.document.dueDate, "x").isValid() ? moment(this.document.dueDate, "x").format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
                                onChange={action((e) => {
                                    const data = moment(e.target.value, "YYYY-MM-DD");

                                    if (data.isValid()) {
                                        this.document.dueDate = data.format("x");
                                    }
                                })}
                            />
                        </div>
                        <div className="col">
                            <Form.Label>Select document date</Form.Label>
                            <Form.Control
                                type="date"
                                value={moment(this.document.documentDate, "x").isValid() ? moment(this.document.documentDate, "x").format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
                                onChange={action((e) => {
                                    const data = moment(e.target.value, "YYYY-MM-DD");

                                    if (data.isValid()) {
                                        this.document.documentDate = data.format("x");
                                    }
                                })}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <Form.Label>About</Form.Label>
                            <Form.Control type="text" value={this.document.about} onChange={action((e) => (this.document.about = e.target.value))} />
                        </div>
                    </div>

                    <Idents idents={this.document.idents} units={this.props.units} materials={this.props.materials} taxRates={this.props.taxRates} collectData={action((idents) => (this.document.idents = idents))} store={this.props.store} />

                    <div className="row mb-3">
                        <div className="col">
                            <Form.Label>Notes</Form.Label>
                            <textarea className="form-control" onChange={action((e: any) => (this.document.notes = e.target.value))}>
                                {this.document.notes}
                            </textarea>
                        </div>
                    </div>
                </Form.Group>
            </>
        );
    }
}
