import _ from "lodash";
import { action, makeObservable, observable, toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Form } from "react-bootstrap";
import Table from "../../../components/ui/Table";

const Options = {
    boolean: "Boolean",
    string: "String",
    json: "JSON",
};

type OptionType = keyof typeof Options;

@observer
export default class OptionsEdit extends React.Component<{ options: { [id: string]: any }; collectData: (options: { [id: string]: any }[]) => void }> {
    @observable
    options: { [id: string]: any } = {};

    @observable
    name: string = "";
    @observable
    type: OptionType = "boolean";
    @observable
    value: any;

    //mode 1 == adding, mode 2 == editing
    @observable
    mode = 1;

    constructor(props: any) {
        super(props);

        makeObservable(this);

        this.options = props.options;
    }

    @action
    add = () => {
        if (this.type === "string") {
            this.options[this.name] = this.value;
        } else if (this.type === "json") {
            this.options[this.name] = JSON.parse(this.value);
        } else if (this.type === "boolean") {
            this.options[this.name] = this.value === "true" ? true : false;
        }
    };

    @action
    edit = (name: string, value: any) => {
        //detect type
        if (typeof value === "string") {
            this.type = "string";
            this.value = value;
        } else if (typeof value === "object") {
            this.type = "json";
            this.value = JSON.stringify(value);
        } else if (typeof value === "boolean") {
            this.type = "boolean";
            this.value = `${value}`;
        }

        this.name = name;

        this.mode = 2;
    };

    @action
    delete = (name: string) => {
        this.options[name] = null;
    };

    render(): React.ReactNode {
        const options = [];
        for (const i in this.options) {
            let value;
            let type = typeof this.options[i];

            if (type === "string") {
                value = this.options[i];
            } else if (type === "object") {
                value = JSON.stringify(this.options[i]);
            } else if (type === "boolean") {
                value = `${this.options[i]}`;
            }

            options.push({
                name: i,
                value,
                type
            });
        }

        return (
            <>
                <Table
                    columns={[
                        {
                            Header: "Name",
                            accessor: "name",
                        },
                        {
                            Header: "Type",
                            accessor: "type",
                        },
                        {
                            Header: "Value",
                            accessor: "value",
                        },
                        {
                            Header: "Actions",
                            accessor: "action",
                            actions: (row: any) => {
                                return (
                                    <div className="btn-group btn-group-sm">
                                        <button type="button" className="btn btn-outline-theme btn-sm" onClick={() => this.edit(row.original.name, row.original.value)}>
                                            Edit
                                        </button>
                                        <button type="button" className="btn btn-outline-theme btn-sm" onClick={() => this.delete(row.original.name)}>
                                            Delete
                                        </button>
                                    </div>
                                );
                            },
                        },
                    ]}
                    data={options}
                    showFooter={false}
                    defaultPageSize={500}
                />
                <div className="row mb-3">
                    <div className="col">
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control className="form-control-sm" type="text" disabled={this.mode === 2} value={this.name} onChange={action((e) => (this.name = e.target.value))} />
                        </Form.Group>
                    </div>
                    <div className="col">
                        <Form.Group className="mb-3">
                            <Form.Label>Type</Form.Label>
                            <Form.Select
                                placeholder="Type"
                                value={this.type}
                                onChange={action((e) => {
                                    if (e.target.value === "string") {
                                        this.type = "string";
                                    } else if (e.target.value === "json") {
                                        this.type = "json";
                                    } else if (e.target.value === "boolean") {
                                        this.type = "boolean";
                                    }
                                })}>
                                <option>Select ...</option>

                                {_.map(Options, (entry, index) => {
                                    return (
                                        <option key={entry} value={index}>
                                            {entry}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col">
                        <Form.Group className="mb-3">
                            <Form.Label>Value</Form.Label>
                            <Form.Control className="form-control-sm" type="text" value={this.value} onChange={action((e) => (this.value = e.target.value))} />
                        </Form.Group>
                    </div>
                    <div className="col">
                        <button type="button" className="btn btn-sm btn-outline-theme rounded-0 mt-28" onClick={this.add}>
                            {this.mode === 1 && (
                                <>
                                    <i className="fa fa-fw fa-plus" />
                                    &nbsp;
                                    <span>Add</span>
                                </>
                            )}
                            {this.mode === 2 && (
                                <>
                                    <i className="fa fa-fw fa-pencil" />
                                    &nbsp;
                                    <span>Update</span>
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </>
        );
    }
}
