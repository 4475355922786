import Api from "../utils/api";


export default class FoldersApi extends Api {
    private static PREFIX = "/api/v1/folders";

    get_current_errors = async () => {
        return (await this.axios.get(`${FoldersApi.PREFIX}/errors/current`)).data;
    }

    get_compnay_blacklist = async () => {
        return (await this.axios.get(`${FoldersApi.PREFIX}/errors/blacklist`)).data;
    }

    trigger_rescan = async () => {
        return (await this.axios.get(`${FoldersApi.PREFIX}/trigger/rescan`)).data;
    }

    disconnect_link = async (documentId: string) => {
        return (await this.axios.post(`${FoldersApi.PREFIX}/test/disconnect_link/${documentId}`)).data;
    }
}