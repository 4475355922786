import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Form } from "react-bootstrap";
import { Ident, Material, TaxRate, Units } from "shared/models-web";
import Table from "../../../components/ui/Table";
import _ from "lodash";
import { printf } from "fast-printf";
import Store from "../../../libs/store";

@observer
export default class Idents extends React.Component<{ idents: Ident[]; units: Units[]; materials: Material[]; taxRates: TaxRate[]; collectData: (contacts: Ident[]) => void; store: Store }> {
    identStartRef: HTMLInputElement | null = null;

    @observable
    idents: Ident[] = [];
    @observable
    posPointerNext = 1;
    @observable
    ident: Ident = {
        amount: 0,
        discount: {
            amount: 0,
        },
        status: {
            status: this.props.store.settings.documents.idents.defaultStatus,
            prevStatuses: []
        },
        price: 0,
        text: "",
        pos: 1,
        taxId: "",
    };

    @observable
    amount = "";
    @observable
    price = "";

    //mode 1 == adding, mode 2 == editing
    @observable
    mode = 1;

    constructor(props: any) {
        super(props);

        makeObservable(this);
        this.idents = props.idents;
        this.posPointerNext = this.idents.length + 1;
        this.ident.pos = this.posPointerNext;
    }

    @action
    add_ident = () => {
        if (this.ident.taxId === "") {
            return;
        }

        if (this.mode === 1) {
            this.idents.push(this.ident);
            this.posPointerNext++;
        }

        this.ident = {
            amount: 0,
            discount: {
                amount: 0,
            },
            status: {
                status: this.props.store.settings.documents.idents.defaultStatus,
                prevStatuses: []
            },
            price: 0,
            text: "",
            pos: this.posPointerNext,
            taxId: "",
        };
        this.mode = 1;
        this.price = "";
        this.amount = "";
        this.props.collectData(this.idents);

        if (this.identStartRef) {
            this.identStartRef.focus();
        }
    };

    @action
    remove_ident = (ident: Ident) => {
        this.idents = this.idents.filter((item) => item !== ident);

        this.props.collectData(this.idents);
    };

    @action
    edit_ident = (ident: Ident) => {
        this.ident = ident;
        this.price = this.ident.price.toString();
        this.amount = this.ident.amount.toString();
        this.mode = 2;
    };

    render(): React.ReactNode {
        return (
            <>
                <Table
                    columns={[
                        {
                            Header: "Pos",
                            accessor: "pos",
                        },
                        {
                            Header: "Text",
                            accessor: "text",
                        },
                        {
                            Header: "Amount",
                            accessor: "amount",
                            actions: (row: any) => {
                                return <>{printf("%.2f", row.original.amount)}</>;
                            },
                        },
                        {
                            Header: "Price",
                            accessor: "price",
                            actions: (row: any) => {
                                return <>{printf("%.2f", row.original.price)}</>;
                            },
                        },
                        {
                            Header: "Discount",
                            accessor: "discount.amount",
                            actions: (row: any) => {
                                return <>{printf("%.2f", row.original.discount.amount * 100)}</>;
                            },
                        },
                        {
                            Header: "Unit",
                            accessor: "unit",
                            actions: (row: any) => {
                                const entry = _.find(this.props.units, { _id: row.original.unitId });

                                if (!entry) {
                                    return <></>;
                                }

                                return <>{entry.name}</>;
                            },
                        },
                        {
                            Header: "Tax",
                            accessor: "tax",
                            actions: (row: any) => {
                                const entry = _.find(this.props.taxRates, { _id: row.original.taxId });

                                if (!entry) {
                                    return <></>;
                                }

                                return <>{entry.name}</>;
                            },
                        },
                        {
                            Header: "Material",
                            accessor: "material",
                            actions: (row: any) => {
                                const entry = _.find(this.props.materials, { _id: row.original.materialId });

                                if (!entry) {
                                    return <></>;
                                }

                                return <>{entry.text ? entry.text : entry.name}</>;
                            },
                        },
                        {
                            Header: "Actions",
                            accessor: "action",
                            actions: (row: any) => {
                                return (
                                    <div className="btn-group btn-group-sm">
                                        <button type="button" className="btn btn-outline-theme btn-sm" onClick={() => this.edit_ident(row.original)}>
                                            Edit
                                        </button>
                                        <button type="button" className="btn btn-outline-theme btn-sm" onClick={() => this.remove_ident(row.original)}>
                                            Delete
                                        </button>
                                    </div>
                                );
                            },
                        },
                    ]}
                    data={this.idents}
                    showFooter={false}
                    defaultPageSize={500}
                />

                <div className="row">
                    <div className="col">
                        <Form.Group className="mb-3">
                            <Form.Label>Pos.</Form.Label>
                            <Form.Control className="form-control-sm" type="text" disabled={true} value={this.ident.pos} />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mb-3">
                            <Form.Label>Text</Form.Label>
                            <Form.Control ref={(input: HTMLInputElement | null) => (this.identStartRef = input)} className="form-control-sm" type="text" value={this.ident.text} onChange={action((e) => (this.ident.text = e.target.value))} />
                        </Form.Group>
                    </div>
                    <div className="col">
                        <Form.Group className="mb-3">
                            <Form.Label>Amount</Form.Label>
                            <Form.Control
                                className="form-control-sm"
                                type="text"
                                value={this.amount}
                                onChange={action((e) => {
                                    this.amount = e.target.value;
                                })}
                                onBlur={action((e) => {
                                    const raw = e.target.value.replace(",", ".");
                                    const amount = Number.parseFloat(raw);

                                    if (!isNaN(amount)) {
                                        this.ident.amount = amount;
                                    } else {
                                        this.ident.amount = 0;
                                    }

                                    this.amount = this.ident.amount.toString();

                                    console.log(this.ident.amount);
                                })}
                            />
                        </Form.Group>
                    </div>
                    <div className="col">
                        <Form.Group className="mb-3">
                            <Form.Label>Price</Form.Label>
                            <Form.Control
                                className="form-control-sm"
                                type="text"
                                value={this.price}
                                onChange={action((e) => {
                                    this.price = e.target.value;
                                })}
                                onBlur={action((e) => {
                                    const raw = e.target.value.replace(",", ".");
                                    const price = Number.parseFloat(raw);

                                    if (!isNaN(price)) {
                                        this.ident.price = price;
                                    } else {
                                        this.ident.price = 0;
                                    }

                                    this.price = this.ident.price.toString();
                                })}
                            />
                        </Form.Group>
                    </div>
                    <div className="col">
                        <Form.Group className="mb-3">
                            <Form.Label>Discount</Form.Label>
                            <Form.Control
                                className="form-control-sm"
                                type="text"
                                value={this.ident.discount && this.ident.discount.amount * 100}
                                onChange={action((e) => {
                                    const discount = Number.parseFloat(e.target.value);

                                    if (!this.ident.discount) {
                                        this.ident.discount = {
                                            amount: discount / 100,
                                        };
                                        return;
                                    }

                                    if (!isNaN(discount)) {
                                        this.ident.discount.amount = discount / 100;
                                    } else {
                                        this.ident.discount.amount = 0;
                                    }
                                })}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-2">
                        <Form.Group className="mb-3">
                            <Form.Label>Unit</Form.Label>
                            <Form.Select className="form-select-sm" value={this.ident.unitId} onChange={action((e) => (this.ident.unitId = e.target.value))}>
                                <option>Select ...</option>

                                {_.map(this.props.units, (entry, index) => {
                                    return (
                                        <option key={entry._id} value={entry._id}>
                                            {entry.name}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-md-2">
                        <Form.Group className="mb-3">
                            <Form.Label>Material</Form.Label>
                            <Form.Select className="form-select-sm" value={this.ident.materialId} onChange={action((e) => (this.ident.materialId = e.target.value))}>
                                <option>Select ...</option>

                                {_.map(this.props.materials, (entry, index) => {
                                    return (
                                        <option key={entry._id} value={entry._id}>
                                            {entry.name}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-md-2">
                        <Form.Group className="mb-3">
                            <Form.Label>Tax rate</Form.Label>
                            <Form.Select className="form-select-sm" value={this.ident.taxId} onChange={action((e) => (this.ident.taxId = e.target.value))}>
                                <option>Select ...</option>

                                {_.map(this.props.taxRates, (entry, index) => {
                                    return (
                                        <option key={entry._id} value={entry._id}>
                                            {entry.name}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <button type="button" className="btn btn-sm btn-outline-theme rounded-0 offset-md-10 col" onClick={this.add_ident}>
                        {this.mode === 2 ? (
                            <>
                                <i className="fa fa-fw fa-pencil" />
                                &nbsp;
                                <span>Update</span>
                            </>
                        ) : (
                            <>
                                <i className="fa fa-fw fa-plus" />
                                &nbsp;
                                <span>Add</span>
                            </>
                        )}
                    </button>
                </div>
            </>
        );
    }
}

//
