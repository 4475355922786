import { action, makeObservable, observable, observe, toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Alert, Button, Form, InputGroup } from "react-bootstrap";
import { Company, PaymentDueDate, TaxRate } from "shared/models-web";
import Store from "../../../libs/store";
import _ from "lodash";
import ContactEdit from "./ContactsEdit";

const CountryCodes: { [id: string]: string } = {
    Austria: "AT",
    Belgium: "BE",
    Bulgaria: "BG",
    Croatia: "HR",
    Cyprus: "CY",
    CzechRepublic: "CZ",
    Denmark: "DK",
    Estonia: "EE",
    Finland: "FI",
    France: "FR",
    Germany: "DE",
    Greece: "EL",
    Hungary: "HU",
    Ireland: "IE",
    Italy: "IT",
    Latvia: "LV",
    Lithuania: "LT",
    Luxembourg: "LU",
    Malta: "MT",
    Netherlands: "NL",
    Poland: "PL",
    Portugal: "PT",
    Romania: "RO",
    Slovakia: "SK",
    Slovenija: "SI",
    Srbija: "RS",
    Spain: "ES",
    Sweden: "SE",
    NorthernIreland: "XI",
};

const dateType = ["days", "months"];

@observer
export default class CompanyEdit extends React.Component<{ store: Store; popupHasError: boolean; popupErrorMessage?: string; company?: Company; taxRates: TaxRate[]; loadFromInternet: (vatId?: string) => Promise<void>; onChange: (company: Company) => void; getInformer: (informer: (company: Company) => void) => void }> {
    @observable
    company: Company = {
        name: "",
        contacts: [],
    };
    disposer1?: any;

    constructor(props: any) {
        super(props);

        makeObservable(this);

        if (props.company) {
            this.company = props.company;
        }

        this.attachObserver();
        this.props.getInformer(this.informer);
    }

    @action
    informer = (company: Company) => {
        this.company = Object.assign({}, this.company, company);

        this.props.onChange(this.company);
        
        this.attachObserver();
    };

    componentWillUnmount = () => {
        this.disposer1 && this.disposer1();
    };

    attachObserver = () => {
        this.disposer1 && this.disposer1();

        this.disposer1 = observe(this.company, () => {
            this.props.onChange(this.company);
        });
    };

    render(): React.ReactNode {
        return (
            <>
                <Alert show={this.props.popupHasError} variant="danger">
                    {this.props.popupErrorMessage}
                </Alert>

                <h3>Company info</h3>
                <div className="row mb-3">
                    <div className="col">
                        <Form.Label>Vat Number</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control
                                type="text"
                                value={this.company.vatId}
                                onChange={action((e) => {
                                    this.company.vatId = e.target.value;
                                })}
                            />
                            <Button
                                variant="success"
                                onClick={() => {
                                    this.props.loadFromInternet(this.company.vatId);
                                }}>
                                Load from internet
                            </Button>
                        </InputGroup>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Form.Label>Company name</Form.Label>
                        <Form.Control
                            type="text"
                            value={this.company.name}
                            onChange={action((e) => {
                                this.company.name = e.target.value;
                            })}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                            type="text"
                            value={this.company.address && this.company.address.address}
                            onChange={action((e) => {
                                if (!this.company.address) {
                                    this.company.address = {
                                        address: e.target.value,
                                        city: "",
                                        country: "",
                                        post_code: "",
                                    };
                                    return;
                                }

                                this.company.address.address = e.target.value;
                            })}
                        />
                    </div>
                    <div className="col">
                        <Form.Label>Post code</Form.Label>
                        <Form.Control
                            type="text"
                            value={this.company.address && this.company.address.post_code}
                            onChange={action((e) => {
                                if (!this.company.address) {
                                    this.company.address = {
                                        address: "",
                                        city: "",
                                        country: "",
                                        post_code: e.target.value,
                                    };
                                    return;
                                }

                                this.company.address.post_code = e.target.value;
                            })}
                        />
                    </div>
                    <div className="col">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                            type="text"
                            value={this.company.address && this.company.address.city}
                            onChange={action((e) => {
                                if (!this.company.address) {
                                    this.company.address = {
                                        address: "",
                                        city: e.target.value,
                                        country: "",
                                        post_code: "",
                                    };
                                    return;
                                }

                                this.company.address.city = e.target.value;
                            })}
                        />
                    </div>
                    <div className="col">
                        <Form.Label>Country</Form.Label>
                        <Form.Select
                            placeholder="Content"
                            value={this.company.address && this.company.address.country}
                            onChange={action((e) => {
                                if (!this.company.address) {
                                    this.company.address = {
                                        address: "",
                                        city: "",
                                        country: e.target.value,
                                        post_code: "",
                                    };
                                    return;
                                }

                                this.company.address.country = e.target.value;
                            })}>
                            <option>Select ...</option>

                            {_.map(CountryCodes, (entry, index) => {
                                return (
                                    <option key={index} value={index}>
                                        {index}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </div>
                </div>
                <hr />
                <h3>Contacts</h3>
                <ContactEdit contacts={this.company.contacts} collectData={action((contacts) => (this.company.contacts = contacts))} />
                <hr />
                <h3>Default tax rate</h3>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <Form.Label>Tax rate</Form.Label>
                        <Form.Select placeholder="Select tax rate" value={this.company.taxRateId} onChange={action((e) => (this.company.taxRateId = e.target.value))}>
                            <option>Select ...</option>

                            {_.map(this.props.taxRates, (entry, index) => {
                                return (
                                    <option key={entry._id} value={entry._id}>
                                        {entry.name}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </div>
                </div>
                <h3>Default discount</h3>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <Form.Label>Discount Amount</Form.Label>
                        <Form.Control
                            type="text"
                            value={this.company.discount && this.company.discount.amount * 100}
                            onChange={action((e) => {
                                const amount = Number.parseFloat(e.target.value);

                                if (isNaN(amount)) {
                                    this.company.discount = undefined;
                                    return;
                                }

                                if (!this.company.discount) {
                                    this.company.discount = {
                                        amount: amount / 100,
                                    };
                                    return;
                                }

                                this.company.discount.amount = amount / 100;
                            })}
                        />
                    </div>
                    <div className="col-md-4">
                        <Form.Label>Discount Reason</Form.Label>
                        <Form.Control
                            type="text"
                            value={this.company.discount && this.company.discount.name}
                            onChange={action((e) => {
                                if (!this.company.discount) {
                                    this.company.discount = {
                                        amount: 0,
                                        name: e.target.value,
                                    };
                                    return;
                                }

                                this.company.discount.name = e.target.value;
                            })}
                        />
                    </div>
                </div>
                <h3>Payment due date</h3>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <Form.Label>Value</Form.Label>
                        <Form.Control
                            type="text"
                            value={this.company.paymentDueDate && this.company.paymentDueDate.value}
                            onChange={action((e) => {
                                if (!this.company.paymentDueDate) {
                                    this.company.paymentDueDate = {} as PaymentDueDate;
                                }

                                this.company.paymentDueDate.value = e.target.value;
                            })}
                        />
                    </div>
                    <div className="col-md-4">
                        <Form.Label>Type</Form.Label>
                        <Form.Select
                            placeholder="Select type"
                            value={this.company.paymentDueDate && this.company.paymentDueDate.type}
                            onChange={action((e) => {
                                if (!this.company.paymentDueDate) {
                                    this.company.paymentDueDate = {} as PaymentDueDate;
                                }

                                this.company.paymentDueDate.type = e.target.value;
                            })}>
                            <option value="-1">Select ...</option>
                            {_.map(dateType, (entry, index) => {
                                return (
                                    <option key={entry} value={entry}>
                                        {entry}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </div>
                </div>
            </>
        );
    }
}
