import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Form, ListGroup } from "react-bootstrap";
import { Permissions, User } from "shared/models-web";
import Store from "../../../libs/store";
import PermissionsEntry from "./PermissionsEntry";

export default observer(function Permissions(props: { user: User; store: Store; onChange: (permissions: Permissions) => void }) {
    const [permissions, setPermissions] = useState({
        isAdmin: props.user.permissions.isAdmin,
        isHidden: props.user.permissions.isHidden,
        entries: props.user.permissions.entries,
    } as Permissions);

    const [abilities, setAbilities] = useState({
        canRead: false,
        canWrite: false,
        canDelete: false,
    });

    useEffect(() => {
        const load = async () => {
            const userId = props.store.get_sessions().user_id;
            if (!userId) {
                return;
            }

            const data = (await props.store.userApi.check_permission(userId, "users.permissions")).data;

            setAbilities(data);
        };

        load();
    }, [props.user]);

    useEffect(() => {
        props.onChange(permissions);
    }, [permissions]);

    if (!abilities.canRead) {
        return <></>;
    }

    return (
        <>
            <h3>Permissions</h3>

            <Form.Group className="mb-3" controlId="email">
                <Form.Check
                    type="switch"
                    checked={permissions.isAdmin}
                    label="isAdmin"
                    onChange={(e) => {
                        const newPermissions = Object.assign({}, permissions);
                        newPermissions.isAdmin = e.target.checked;
                        setPermissions(newPermissions);
                    }}
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="email">
                <Form.Check
                    type="switch"
                    checked={permissions.isHidden}
                    label="isHidden"
                    onChange={(e) => {
                        const newPermissions = Object.assign({}, permissions);
                        newPermissions.isHidden = e.target.checked;
                        setPermissions(newPermissions);
                    }}
                />
            </Form.Group>

            <PermissionsEntry
                permissions={permissions}
                onChange={(entries) => {
                    const newPermissions = Object.assign({}, permissions);
                    newPermissions.entries = entries;
                    setPermissions(newPermissions);
                }}
            />
        </>
    );
});
