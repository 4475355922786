import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { User } from "shared/models-web";
import Store from "../../../libs/store";
import Permissions from "./Permissions";

export default observer(function UserEditPopup(props: { popupHasError: boolean; popupErrorMessage?: string; userSource: User; store: Store; onChange: (user: User, password: { newPassword: string; repeatedPassword: string }) => void }) {
    const [user, setUser] = useState(props.userSource);
    const [password, setPassword] = useState({
        newPassword: "",
        repeatedPassword: "",
    });

    useEffect(() => {
        props.onChange(user, password);
    }, [user, password]);

    return (
        <>
            {props.popupHasError && <Alert variant="danger">{props.popupErrorMessage}</Alert>}

            <Form.Group className="mb-3" controlId="firstName">
                <Form.Label>First name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="First name"
                    value={user.firstName}
                    onChange={(e) => {
                        const newUser = Object.assign({}, user);

                        newUser.firstName = e.target.value;

                        setUser(newUser);
                    }}
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="lastName">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Last name"
                    value={user.lastName}
                    onChange={(e) => {
                        const newUser = Object.assign({}, user);

                        newUser.lastName = e.target.value;

                        setUser(newUser);
                    }}
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="username">
                <Form.Label>Username</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Username"
                    value={user.username}
                    onChange={(e) => {
                        const newUser = Object.assign({}, user);

                        newUser.username = e.target.value;

                        setUser(newUser);
                    }}
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="email"
                    autoComplete="off"
                    placeholder="Email"
                    value={user.email}
                    onChange={(e) => {
                        const newUser = Object.assign({}, user);

                        newUser.email = e.target.value;

                        setUser(newUser);
                    }}
                />
            </Form.Group>

            {props.store.get_auth_type() === "password" && (
                <>
                    <Form.Group className="mb-3" controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            autoComplete="new-password"
                            placeholder="Password"
                            value={password.newPassword}
                            onChange={(e) => {
                                const newPassword = Object.assign({}, password);

                                newPassword.newPassword = e.target.value;

                                setPassword(newPassword);
                            }}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="password2">
                        <Form.Label>Password repeated</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            value={password.repeatedPassword}
                            onChange={(e) => {
                                const newPassword = Object.assign({}, password);

                                newPassword.repeatedPassword = e.target.value;

                                setPassword(newPassword);
                            }}
                        />
                    </Form.Group>
                </>
            )}

            <Permissions user={user} store={props.store} onChange={(permissions) => {
                const newUser = Object.assign({}, user);

                newUser.permissions = permissions;

                setUser(newUser);
            }}/>
        </>
    );
});
