import Api from "../utils/api";


export default class EditorApi extends Api {
    private static PREFIX = "/api/v1/editor";

    options = async () => {
        return (await this.axios.get(`${EditorApi.PREFIX}/`)).data;
    }

    save_options = async () => {
        return (await this.axios.get(`${EditorApi.PREFIX}/save`)).data;
    }

    context = async () => {
        return (await this.axios.get(`${EditorApi.PREFIX}/context`)).data;
    }

    list_files = async () => {
        return (await this.axios.get(`${EditorApi.PREFIX}/file/list`)).data;
    }

    load_file = async (filePath: string) => {
        return (await this.axios.post(`${EditorApi.PREFIX}/file`, { filePath })).data;
    }

    save_file = async (filePath: string, content: string) => {
        return (await this.axios.post(`${EditorApi.PREFIX}/file/save`, { filePath, content })).data;
    }

    delete_file = async (filePath: string) => {
        return (await this.axios.post(`${EditorApi.PREFIX}/file/delete`, { filePath })).data;
    }
}