

export async function timeout(timeout: number) {
    return new Promise((resolve, reject) => setTimeout(resolve, timeout));
}

export async function generateRange(): Promise<string[]> {
    const results = [];

    for (let i = 0; i <= 9; i++) {
        results.push(Number(i).toString())
    }

    for (let i = 'a'.charCodeAt(0); i <= 'z'.charCodeAt(0); i++) {
        results.push(String.fromCharCode(i));
    }

    return results;
}

// credit: Typescript documentation, src 
// https://www.typescriptlang.org/docs/handbook/advanced-types.html#index-types
export function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
    return o[propertyName]; // o[propertyName] is of type T[K]
}

export function getStringFromArraBuffer(ar: ArrayBuffer): string {
    var enc = new TextDecoder("utf-8");

    return enc.decode(ar);
}

export function getJSONFromArraBuffer(ar: ArrayBuffer): any {
    var enc = new TextDecoder("utf-8");

    return JSON.parse(enc.decode(ar));
}

export function toBase64(text: string) {
    return Buffer.from(text, "utf-8").toString("base64");
}

export function fromBase64(base64: string) {
    return Buffer.from(base64, "base64").toString("utf-8");
}

export function toBase64Json(json: any) {
    return toBase64(JSON.stringify(json));
}

export function fromBase64Json(base64: string) {
    return JSON.parse(fromBase64(base64));
}

export function toBase64url(text: string) {
    return encodeURIComponent(toBase64(text));
}

export function fromBase64url(base64: string) {
    return decodeURIComponent(fromBase64(base64))
}

export function toBase64urlJson(json: any) {
    return encodeURIComponent(toBase64Json(json));
}

export function fromBase64urlJson(base64: string) {
    return decodeURIComponent(fromBase64url(base64))
}