import React from "react";

export const IndeterminateCheckbox = React.forwardRef((props: any, ref) => {
    const { indeterminate, ...rest } = props;

    const defaultRef = React.useRef();
    const resolvedRef = (ref || defaultRef) as any;

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
        <>
            <input className="form-check-input" type="checkbox" ref={resolvedRef} {...rest} />
        </>
    );
});
