import Session from "../utils/session";
import consola from 'consola';
import jwt_decode from "jwt-decode";
import moment from 'moment';

const logger = consola.withTag("token:session");

export default class TokenSession extends Session<string> {
    private token?: string;
    public user_id?: string;

    init_session = async (): Promise<void> => {
        try {
            const data = window.localStorage.getItem("token");

            if (data) {
                this.token = data;

                try {
                    const decoded = jwt_decode(data) as any;

                    this.user_id = decoded.id;

                    if (moment(decoded.exp, "X").isBefore()) {
                        window.localStorage.removeItem('token');
                        window.location.reload();
                    }
                } catch (e) {
                    logger.error(e);

                    window.localStorage.removeItem('token');
                    window.location.reload();
                }
            }

            this.emit('session.updated', this.token);
        } catch (e) {
            logger.error(e);
        }
    }

    update_session = async (input?: string): Promise<boolean> => {
        if (!input) {
            window.localStorage.removeItem('token');
        } else {
            window.localStorage.setItem('token', input);
        }

        if (input) {
            try {
                const decoded = jwt_decode(input) as any;

                this.user_id = decoded.id;

                if (moment(decoded.exp, "X").isBefore()) {
                    window.localStorage.removeItem('token');
                    window.location.reload();
                }
            } catch (e) {
                logger.error(e);

                window.localStorage.removeItem('token');
                window.location.reload();
            }
        }

        this.token = input;

        this.emit('session.updated', this.token);

        return true;
    }

    get_session = async (): Promise<string> => {
        if (!this.token) {
            throw new Error("Session is invalid");
        }

        return this.token;
    }

    is_valid = async (): Promise<boolean> => {
        if(!this.token) {
            return false;
        }

        const data = window.localStorage.getItem("token");

        if(!data) {
            return false;
        }

        try {
            const decoded = jwt_decode(this.token) as any;

            if (moment(decoded.exp, "X").isBefore()) {
                return false;
            }
            return true;
        } catch (e) {
            logger.error(e);
            
            return false;
        }
    }
}