import Store from "../store";
import PasswordAuth from "./password";
import TokenSession from "./token";



export function get_session_provider(store: Store, type: string) {
    switch (type) {
        case 'token':
            return new TokenSession(store);
    }

    throw new Error("Invalid session provider");
}

export function get_auth_provider(store: Store, type: string) {
    switch (type) {
        case 'password':
            return new PasswordAuth(store);
    }

    throw new Error("Invalid auth provider");
}