import { BarChart, Envelope, Gem, Bank, Palette2, Rulers, Percent, PeopleFill, Files, Tools, Bandaid, Gear, ColumnsGap } from "react-bootstrap-icons";
import { Link, useLocation } from "react-router-dom";
import Store from "../../libs/store";
import _ from "lodash";
import React from "react";
import { observer } from "mobx-react";
import { action, makeObservable, observable } from "mobx";

@observer
export default class Menu extends React.Component<{ store: Store }> {
    @observable
    public folderScanerStaus = {
        errors: 0,
    };

    constructor(props: any) {
        super(props);

        makeObservable(this);
    }

    componentDidMount = () => {
        this.props.store.on("folders.error", this.onData);
    };

    componentWillUnmount = () => {
        this.props.store.off("folders.error", this.onData);
    };

    @action
    onData = async (data: {
        company_blacklist: string[];
        current_errors: {
            [id: string]: {
                section: string;
                matcher: string;
                text: string;
            }[];
        };
    }) => {
        // this.current_errors = data.current_errors;

        this.folderScanerStaus.errors = _.filter(data.current_errors, (item) => item.length > 0).reduce((prev, curr, i, all) => prev + curr.length, 0);
    };

    render(): React.ReactNode {
        return <MenuInner store={this.props.store} folder_errors={this.folderScanerStaus.errors} />;
    }
}

function MenuInner(props: { store: Store; folder_errors: number }) {
    const location = useLocation();

    return (
        <>
            <div id="sidebar" className="app-sidebar">
                <div className="app-sidebar-content" data-scrollbar="true" data-height="100%">
                    <div className="menu">
                        <div className="menu-header">Home</div>
                        <div className={"menu-item " + (location.pathname === "/" ? "active" : "")}>
                            <Link to="/" className="menu-link">
                                <span className="menu-icon">
                                    <Gem />
                                </span>
                                <span className="menu-text">Dashboard</span>
                            </Link>
                        </div>
                        {(props.store.check_permissions("codetables", { read: true }) || props.store.check_permissions("companies", { read: true })) && <div className="menu-header">Codetables / Generic data</div>}
                        {props.store.check_permissions("companies", { read: true }) && (
                            <div className={"menu-item " + (location.pathname === "/ct/companies" ? "active" : "")}>
                                <Link to="/ct/companies" className="menu-link">
                                    <span className="menu-icon">
                                        <Bank />
                                    </span>
                                    <span className="menu-text">Companies</span>
                                </Link>
                            </div>
                        )}
                        {props.store.check_permissions("codetables", { read: true }) && (
                            <div className={"menu-item " + (location.pathname === "/ct/materials" ? "active" : "")}>
                                <Link to="/ct/materials" className="menu-link">
                                    <span className="menu-icon">
                                        <Palette2 />
                                    </span>
                                    <span className="menu-text">Materials</span>
                                </Link>
                            </div>
                        )}
                        {props.store.check_permissions("codetables", { read: true }) && (
                            <div className={"menu-item " + (location.pathname === "/ct/units" ? "active" : "")}>
                                <Link to="/ct/units" className="menu-link">
                                    <span className="menu-icon">
                                        <Rulers />
                                    </span>
                                    <span className="menu-text">Units</span>
                                </Link>
                            </div>
                        )}
                        {props.store.check_permissions("codetables", { read: true }) && (
                            <div className={"menu-item " + (location.pathname === "/ct/taxrates" ? "active" : "")}>
                                <Link to="/ct/taxrates" className="menu-link">
                                    <span className="menu-icon">
                                        <Percent />
                                    </span>
                                    <span className="menu-text">Tax Rates</span>
                                </Link>
                            </div>
                        )}

                        {props.store.check_permissions("users", { read: true }) && (
                            <>
                                <div className="menu-header">Users</div>
                                <div className={"menu-item " + (location.pathname === "/users" ? "active" : "")}>
                                    <Link to="/users" className="menu-link">
                                        <span className="menu-icon">
                                            <PeopleFill />
                                        </span>
                                        <span className="menu-text">List users</span>
                                    </Link>
                                </div>
                            </>
                        )}

                        {props.store.check_permissions("documents", { read: true }) && (
                            <>
                                <div className="menu-header">Documents</div>
                                {_.map(props.store.settings.documents.types, (item) => {
                                    return (
                                        <div className={"menu-item " + (location.pathname === "/documents/" + item.id ? "active" : "")} key={item.id}>
                                            <Link to={"/documents/" + item.id} className="menu-link">
                                                <span className="menu-icon">
                                                    <Files />
                                                </span>
                                                <span className="menu-text">List {item.name}</span>
                                            </Link>
                                        </div>
                                    );
                                })}
                            </>
                        )}

                        {props.store.check_permissions("projects", { read: true }) && props.store.settings.menu.items.hasOwnProperty("projects") && (
                            <>
                                <div className="menu-header">Projects</div>
                                <div className={"menu-item " + (location.pathname === "/projects" ? "active" : "")}>
                                    <Link to="/projects" className="menu-link">
                                        <span className="menu-icon">
                                            <BarChart />
                                        </span>
                                        <span className="menu-text">List Projects</span>
                                    </Link>
                                </div>
                                <div className={"menu-item " + (location.pathname === "/projects/new" ? "active" : "")}>
                                    <Link to="/projects/new" className="menu-link">
                                        <span className="menu-icon">
                                            <BarChart />
                                        </span>
                                        <span className="menu-text">New Project</span>
                                    </Link>
                                </div>
                                <div className={"menu-item " + (location.pathname === "/projects/analytics" ? "active" : "")}>
                                    <Link to="/projects/analytics" className="menu-link">
                                        <span className="menu-icon">
                                            <BarChart />
                                        </span>
                                        <span className="menu-text">Project Analytics</span>
                                    </Link>
                                </div>
                            </>
                        )}

                        {props.store.check_permissions("folders", { read: true }) && props.store.settings.menu.items.hasOwnProperty("folders") && (
                            <>
                                <div className="menu-header">Folders</div>
                                <div className={"menu-item " + (location.pathname === "/folders/errors" ? "active" : "")}>
                                    <Link to="/folders/errors" className="menu-link">
                                        <span className="menu-icon">
                                            <Bandaid />
                                        </span>
                                        <span className="menu-text">Errors ({props.folder_errors})</span>
                                    </Link>
                                </div>
                            </>
                        )}
                        {props.store.check_permissions("editor", { read: true }) && (
                            <>
                                <div className="menu-header">Settings</div>
                                <div className={"menu-item " + (location.pathname === "/editor/config" ? "active" : "")}>
                                    <Link to="/editor/config" className="menu-link">
                                        <span className="menu-icon">
                                            <Gear />
                                        </span>
                                        <span className="menu-text">Configuration</span>
                                    </Link>
                                </div>
                                <div className={"menu-item " + (location.pathname === "/editor/files" ? "active" : "")}>
                                    <Link to="/editor/files" className="menu-link">
                                        <span className="menu-icon">
                                            <ColumnsGap />
                                        </span>
                                        <span className="menu-text">Templates</span>
                                    </Link>
                                </div>
                            </>
                        )}
                        {/* <div className={"menu-item " + (location.pathname === "/settings/other" ? "active" : "")}>
                            <Link to="/settings/other" className="menu-link">
                                <span className="menu-icon">
                                    <Tools />
                                </span>
                                <span className="menu-text">Other</span>
                            </Link>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}
//
